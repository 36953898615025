<template>
  <button
    v-if="type === 'enviar-contato'"
    type="button"
    class="btnContato"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
  </button>
  <button
    v-if="type === 'fechar-modal'"
    type="button"
    class="btnModal"
    @click="click"
  >
    {{text}}
  </button>
  <button
    v-if="type === 'cookies'"
    type="button"
    class="btnCookies"
    @click="click"
  >
    {{text}}
  </button>
  <button
    v-if="type === 'carrinho'"
    type="button"
    class="btnCarrinho"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconArrowRightWhite">chevron_right</span>
  </button>
  <button
    v-if="type === 'login'"
    type="button"
    class="btnLogin"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconLogin">chevron_right</span>
  </button>
  <button
    v-if="type === 'voltar-login'"
    type="button"
    class="btnBackLogin"
    @click="click"
  >
    <span class="material-icons-outlined iconBackLogin">chevron_left</span>
    {{text}}
  </button>
  <button
    v-if="type === 'meus-dados'"
    type="button"
    class="btnMeusDados"
    @click="click"
  >
    {{text}}
  </button>
  <button
    v-if="type === 'sidebar-minhaArea'"
    type="button"
    class="btnSidebarMinhaArea"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconSidebar">chevron_right</span>
  </button>
  <button
    v-if="type === 'cadastro'"
    type="button"
    class="btnCadastro"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconCadastro">chevron_right</span>
  </button>
  <button
    v-if="type === 'detalhesPlano-adicionar'"
    type="button"
    class="btnDetalhesPlano"
    @click="click"
  >
    {{text}}
    <span class="material-icons-outlined iconDetalhesPlano">chevron_right</span>
  </button>
  <button
    v-if="type === 'detalhesPlano-documentacao'"
    type="button"
    class="btnDocumentacao"
    @click="click"
  >
    {{text}}
    <span v-if="!hasItems" class="material-icons-outlined iconDocumentacao animate__animated animate__rotateIn">expand_more</span> 
    <span v-else class="material-icons-outlined iconDocumentacao animate__animated animate__rotateIn">expand_less</span> 
  </button>
  <button
    v-if="type === 'btn-boleto'"
    type="button"
    class="btnBoleto"
    @click="click"
  >
    {{text}}
  </button>
</template>

<script>
export default {
    props:{
        type: String || null,
        text: String || null,
        click: Function || null,
        hasItems: Boolean || null
    },
    setup(props){
        return {props}
    }
};
</script>

<style scoped>
    .btnContato{
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 2.3125rem;
        border: none;
        background-color: transparent;
        color: #899bb2;
        font-size: .875rem;
        font-weight: 600;
        letter-spacing: -.28px;
        margin-top: .6rem;
        align-self: flex-end;
    }
    .btnContato:focus{
        outline: none;
    }

    .iconArrowRight{
        color: #ff6ea9;
        font-size: 1.4rem;
        margin-left: .3rem;
        font-weight: 600;
    }

    .btnModal{
        color: #0cc971;
        position: absolute;
        right: .8rem;
        bottom: .5rem;
        font-weight: 700;
        border: none;
        user-select: none;
        background-color: #fff;
        padding: .5rem;
    }

    .btnCookies{
      border: none;
      font-size: .75rem;
      font-weight: 700;
      color: #fff;
      margin: 0 1.25rem;
      padding: .625rem 1.25rem;
      outline: 0;
      background-color: #e91e63;
    }

    .btnCarrinho{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #c90c53;
      text-transform: uppercase;
      width: 100%;
      height: 3.4375rem;
      font-size: 1.125rem;
      font-weight: 700;
      border: none
    }

    .iconArrowRightWhite{
      color: #fff;
      font-size: 1.4rem;
      margin-left: 2rem;
      font-weight: 700;
    }

    .btnLogin{
      width: 100%;
      height: 3.4375rem;
      background-color: #00fff5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .3125rem;
      border: none;
      color: #c70851;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .iconLogin{
      color: #c70851;
      font-size: 1.4rem;
      margin-left: 2rem;
      font-weight: 600;
    }

    .btnBackLogin{
      background-color: transparent;
      border: none;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 3.7rem;
      transform: translateY(-.5rem);
    }

    .btnMeusDados{
      background-color: #c90c53;
      color: #fff;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 2.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: .8rem;
      margin: 1rem 0 0 0;
      min-width: 6.25rem;
    }

    .btnSidebarMinhaArea{
      background-color: #c90c53;
      border: none;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      height: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2.5rem;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 20;
    }

    .btnCadastro{
      background-color: #c90c53;
      border: none;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      height: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.5rem;
      position: fixed;
      bottom: 0;
      left: 0;
      text-transform: uppercase;
      z-index: 100;
    }

    .iconSidebar{
      color: #fff;
      font-size: 2rem;
      margin-left: 2rem;
    }
    .iconCadastro{
      color: #fff;
      font-size: 2rem;
      margin-left: 2rem;
    }

    .btnDetalhesPlano{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 5rem;
      background-color: #00fff5;
      cursor: pointer;
      border: none;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 2rem;
      position: fixed;
      bottom: 0;
      left: 0;
      text-transform: uppercase;
      z-index: 100;
    }
    .iconDetalhesPlano{
      color: #000;
      font-size: 2rem;
      margin-left: 2rem;
      font-weight: 600;
    }

    .btnDocumentacao{
      font-size: .9375rem;
      font-weight: 600;
      width: 100%;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: rgba(237, 235, 235, 0.43);
    }

    .iconDocumentacao{
      color: #AF104C;
      margin-left: 2rem;
      font-weight: 600;
    }

    .btnBoleto{
      border-radius: .625rem;
      color: #fff;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #9c324c;
      padding: .625rem;
      border: none;
    }

    @media screen and (min-width: 800px) {
      .btnMeusDados{
        padding: .4rem .8rem;
      }

      .btnSidebarMinhaArea{
        height: 3.4375rem;
        position: relative;
        border-radius: 5px;
      }

      .iconSidebar{
        display: none;
      }

      .btnCadastro{
        position: relative;
        bottom: initial;
        left: initial;
        z-index: initial;
        font-size: 1.125rem;
        height: 3.4375rem;
        border-radius: 5px;
      }

      .btnDetalhesPlano{
        position: relative;
        bottom: initial;
        left: initial;
        z-index: initial;
        text-transform: initial;
        font-size: .9375rem;
        height: 4.375rem;
      }
      .iconDetalhesPlano{
        font-size: 1.5rem;
      }
    }
</style>