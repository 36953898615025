<template>
    <div v-if="store.getters.modalTermsAndPolitics.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container" v-if="store.getters.modalTermsAndPolitics.content === 'terms'">
                <div class="header">
                    <h1 class="title-modal">Termos de Uso</h1>
                </div>
                <div class="body">
                    <h2 class="subtitle-comprodonto">Bem-vindo à COMPRODONTO!</h2>
                    <h2 class="subtitle">O que é a COMPRODONTO?</h2>
                    <p>A COMPRODONTO consiste em um ambiente virtual disponibilizado 
                    através do site www.comprodonto.com.br. No ambiente virtual da 
                    COMPRODONTO, os clientes terão acesso à publicidade de planos 
                    odontológicos oferecidos pelos parceiros da COMPRODONTO (“Parceiros”), 
                    podendo o cliente fazer a comparação de preços entre os planos 
                    odontológicos ofertados de acordo com sua geolocalização e podendo 
                    contratá-los diretamente dos próprios Parceiros através da plataforma 
                    digital da COMPRODONTO. Destacamos que os pagamentos dos produtos 
                    adquiridos por intermédio do site da COMPRODONTO deverão ser pagos 
                    obrigatoriamente através do intermediador de pagamento disponibilizado 
                    pela COMPRODONTO. A COMPRODONTO é opção ideal para facilitar a 
                    contratação de planos odontológicos pelos clientes com as melhores 
                    opções de planos de acordo com a localização dos clientes. Para a 
                    utilização dos serviços oferecidos pela COMPRODONTO é necessário que 
                    você leia e concorde integralmente com os termos de uso abaixo 
                    especificados. A utilização da COMPRODONTO pressupõe sua aceitação dos 
                    respectivo TERMO DE USO. Em caso de dúvidas, consulte-nos por meio da 
                    página eletrônica www.comprodonto.com.br ou via e-mail 
                    contato@comprodonto.com.br.</p>

                    <h2 class="subtitle uppercase">Termos e condições de uso da "Comprodonto"</h2>
                    <p>Esses Termos e Condições de Uso da COMPRODONTO (“Termo”) aplicam-se 
                    aos usuários dos serviços oferecidos pela empresa <span class="uppercase">
                    Mazeapps Desenvolvimento de Software Ltda</span>, inscrito no CNPJ/MF sob o 
                    nº. 25.243.724/0001-97, com endereço na Rua Oscar Marcondes de Paula, 
                    nº. 40, Jardim Vitória, Poços de Caldas/MG, CEP 37701- 289, e-mail: 
                    contato@mazeapps.com.br, por meio do site www.comprodonto.com.br. 
                    Qualquer pessoa (adiante usuário), que utilize os serviços da 
                    <span class="uppercase">Comprodonto</span> deverá ter lido, entendido e aceitado estes Termos e 
                    Condições de Uso e a Política de Privacidade da <span class="uppercase">Comprodonto</span>.</p>
                    
                    <h2 class="subtitle">1. Definições</h2>
                    <p>1.1 Para fins de interpretação do presente documento, os termos e 
                    expressões abaixo terão os seguintes significados: a. <span class="uppercase">Comprodonto</span>: 
                    o marketplace de planos odontológicos operado pela empresa 
                    <span class="uppercase">Mazeapps Desenvolvimento de Software Ltda</span>, 
                    inscrito no CNPJ/MF sob o nº. 25.243.724/0001-97, com endereço 
                    na Rua Oscar Marcondes de Paula, nº. 40, Jardim Vitória, Poços de 
                    Caldas/MG, CEP 37701-289, e-mail: contato@mazeapps.com.br. b. 
                    Usuários: todas as pessoas que se cadastram no site 
                    <span class="uppercase">Comprodonto</span> e recebam uma Conta de 
                    Acesso individual e exclusiva, Clientes ou não. c. 
                    Clientes: todas as pessoas que adquiriram os produtos ou serviços 
                    oferecidos no site da COMPRODONTO pelos Parceiros. d. Conta de 
                    Acesso: credencial digital formada pela combinação de login e senha, 
                    única e intransferível para cada Usuário do site da 
                    <span class="uppercase">Comprodonto</span>, que dá permissão ao 
                    Usuário/Cliente ingressar na área restrita do site da 
                    <span class="uppercase">Comprodonto</span>, onde é possível 
                    verificar a situação dos pedidos, precisão dos dados cadastrais e 
                    situação de solicitações de suporte. e. Parceiros: pessoas jurídicas 
                    que oferecem seus próprios produtos e serviços utilizando o site da 
                    <span class="uppercase">Comprodonto</span>, sendo responsáveis 
                    pelas características, coberturas, preço, envio de documentações e 
                    as demais condições de sua oferta, sem interferência da 
                    <span class="uppercase">Comprodonto</span>. f. Informações pessoais: 
                    qualquer informação que possa ser usada para identificar um 
                    indivíduo, incluindo, mas não se limitado ao seu nome, qualificação 
                    pessoal, inscrição no CPF/MF ou CNPJ/MF, endereço físico ou de 
                    e-mail, número de telefone, demais dados de contato, dados de 
                    cobranças, ou quaisquer outros dados que possam ser suficientes 
                    para serem vinculados a essas informações. g. Informações de acesso: 
                    qualquer informação que possa ser usada para identificar o 
                    Internauta, sua solicitação e atividades na Internet, a interação 
                    com um serviço, as mensagens enviadas ou divulgadas, o Endereço de 
                    Protocolo de Internet (&quot;IP&quot;) e horário de conexão, as 
                    URLs do site de origem e do site que o usuário pode visitar em 
                    seguida, o tipo e o idioma do navegador, um ou mais Cookies que 
                    possam identificar o seu navegador ou a sua conta, informações 
                    gerais e específicas sobre o hardware do seu computador, telefone 
                    móvel ou outro dispositivo, conexão à Internet, sistema operacional 
                    e número da versão utilizada. h. Cookie: arquivo de texto enviado 
                    pelo servidor do site da COMPRODONTO para o computador do internauta, 
                    com a finalidade de identificar o seu computador, personalizar os 
                    acessos e obter dados de navegação, como páginas visitadas ou 
                    links clicados. Um Cookie é atribuído individualmente a cada 
                    computador; ele não pode ser usado para executar programas, 
                    tampouco infectar computadores com códigos maliciosos de qualquer 
                    espécie, tais como vírus, trojans etc., e pode ser lido apenas pelo 
                    servidor que o enviou. i. Criptografia: nome dado ao processo de 
                    codificação de informações. As informações são codificadas 
                    (embaralhadas) na origem e decodificadas no destino, dificultando, 
                    dessa forma, que sejam decifradas durante o tráfego na internet. 
                    j. Firewall: nome dado ao dispositivo físico e/ou lógico de uma 
                    rede de computadores que tem por objetivo aplicar uma política de 
                    segurança a um determinado ponto de controle da rede. Sua função 
                    consiste em regular o tráfego de dados entre redes distintas e 
                    impedir a transmissão e/ou recepção de acessos nocivos ou não 
                    autorizados de uma rede para outra. k. Descompilação: é o ato de 
                    atuar inversamente a uma compilação, ou seja, é a transformação de 
                    código objeto em código fonte;</p>

                    <h2 class="subtitle">2. Objeto</h2>
                    <p>2.1 O objeto deste Termo é estabelecer as regras e condições para 
                    a utilização da COMPRODONTO pelos usuários/clientes através do site 
                    da <span class="uppercase">Comprodonto</span>. 
                    2.2. A <span class="uppercase">Comprodonto</span> não autoriza Parceiros a divulgarem seus produtos 
                    e/ou serviços no seu site sem prévia análise e a celebração do 
                    instrumento contratual competente.</p>

                    <h2 class="subtitle">3. Serviços oferecidos aos usuários</h2>
                    <p>3.1 A <span class="uppercase">Comprodonto</span>, através do seu site, 
                    possibilita a escolha, pelos usuários, de planos odontológicos oferecidos 
                    pelas operadoras de planos odontológicos, previamente cadastrados, a 
                    efetivar solicitações para contratação de planos odontológicos 
                    fornecidos pelos respectivos Parceiros, de acordo com a oferta de 
                    cada um para a área de geolocalização informada pelo Usuário/Cliente. 
                    3.2. A intermediação de pagamento será realizada através do gateway 
                    disponível no carrinho de compras do site. A COMPRODONTO não 
                    intermediará vendas fora de seu site ou com pagamento não intermediado 
                    pelo intermediador de pagamento disponível em seu site. 
                    3.3. O usuário está ciente de que os serviços oferecidos pela 
                    <span class="uppercase">Comprodonto</span> são meramente de 
                    intermediação entre os Clientes e os Parceiros, não sendo atribuição 
                    da <span class="uppercase">Comprodonto</span> o envio de documentações, 
                    contratos e quaisquer outros documentos referentes aos planos 
                    odontológicos eventualmente contratados através do site da 
                    <span class="uppercase">Comprodonto</span>, que serão de 
                    responsabilidade exclusiva dos Parceiros. 3.4. Eventuais 
                    reclamações decorrentes de falhas na prestação de serviços pelos 
                    Parceiros, deverão ser feitas pelo Cliente diretamente à 
                    <span class="uppercase">Comprodonto</span> através dos canais de 
                    atendimento que serão disponibilizados pela COMPRODONTO em seu site, 
                    ficando esta responsável pela intermediação entre o Cliente e o 
                    Parceiro. 3.5. Todos os planos odontológicos oferecidos na 
                    <span class="uppercase">Comprodonto</span> respeitam, ao menos, as 
                    coberturas mínimas previstas no Anexo I da resolução Normativa nº. 428 
                    de 7 de novembro de 2017 da ANS, sendo vedada a comercialização de 
                    qualquer plano odontológico que não atenda aos requisitos legais 
                    vigentes.</p>

                    <h2 class="subtitle">4. Cadastro dos usuários</h2>
                    <p>4.1 Para utilização dos serviços descritos acima, o usuário deverá 
                    se cadastrar no site da <span class="uppercase">Comprodonto</span>. 
                    O usuário deverá ter capacidade jurídica para atos da vida civil e deverá 
                    prestar todas as informações com veracidade e exatidão, assumindo a 
                    responsabilidade pela sua inexatidão, bem como se compromete a mantê-los 
                    devidamente atualizadas. A <span class="uppercase">Comprodonto</span> 
                    não assume qualquer responsabilidade em caso de danos causados pela 
                    inexatidão dos dados informados. 4.2. O Usuário que se cadastrar no 
                    site da <span class="uppercase">Comprodonto</span> utilizando dados 
                    falsos ou de terceiros poderá incorrer nos crimes de falsidade 
                    ideológica, estelionato ou outros delitos mais graves, podendo 
                    responder civil ou criminalmente por tal conduta, nos termos da 
                    lei. 4.3. Em caso de informações incorretas, inverídicas ou não 
                    confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar 
                    documentação que comprove a correção, a COMPRODONTO se reserva o direito 
                    de não concluir o cadastramento em curso ou, ainda, de bloquear o 
                    cadastro já existente, impedindo o usuário de utilizar os serviços 
                    on-line até que, a critério da COMPRODONTO, a situação de anomalia 
                    esteja regularizada. A COMPRODONTO se reserva o direito de impedir, 
                    a seu critério, novos cadastros, ou cancelar os já efetuados, em caso 
                    de ser detectada anomalia que, em sua análise, seja revestida de 
                    gravidade ou demonstre tentativa deliberada de burlar as regras aqui 
                    descritas, obrigatórias para todos os usuários. Também agirá a 
                    <span class="uppercase">Comprodonto</span> de tal forma caso verifique 
                    descumprimento, pelo usuário, de qualquer obrigação prevista no 
                    presente Termo. 4.4. Efetuado, com sucesso, o cadastro, o usuário 
                    terá acesso aos serviços por meio de login e senha, dados esses que 
                    se compromete a não divulgar a terceiros, ficando sob sua exclusiva 
                    responsabilidade qualquer solicitação de serviço que seja feita com o 
                    uso de login e senha de sua titularidade. 4.5. O usuário será 
                    automaticamente descadastrado e ficará impedido de realizar novo 
                    cadastro no site da COMPRODONTO quando for detectada a utilização de 
                    qualquer mecanismo engenhoso tendentes a descompilação do site da 
                    <span class="uppercase">Comprodonto</span>, objetivando a captura de 
                    informações na base de dados ou levantamento de protocolos de 
                    comunicação da <span class="uppercase">Comprodonto</span>.</p>
                    
                    <h2 class="subtitle">5. Obrigações dos usuários</h2>
                    <p>5.1 Para realizar transações no site da 
                    <span class="uppercase">Comprodonto</span>, o usuário deverá ler, 
                    compreender e aceitar expressamente o presente Termo e Condições de 
                    Uso da COMPRODONTO, bem como os demais documentos neste relacionados, 
                    especialmente a Política de Privacidade. 5.2. Efetuado com sucesso o 
                    cadastro do usuário, este se obriga a não divulgar a terceiros login e 
                    senha de acesso, nem permitir o uso de tais informações por terceiros, 
                    responsabilizando-se pelas consequências do uso de login e senha de sua 
                    titularidade. 5.3. O Cliente declara estar ciente que o site da 
                    <span class="uppercase">Comprodonto</span> presta apenas o serviço de 
                    intermediação entre os Clientes e os Parceiros, reconhecendo que ao 
                    contratar um plano odontológico diretamente de um Parceiro, a 
                    <span class="uppercase">Comprodonto</span> não possuirá qualquer 
                    responsabilidade pelos serviços prestados, coberturas e envio de 
                    documentações de responsabilidade do Parceiro, cabendo à 
                    <span class="uppercase">Comprodonto</span> apenas promover a aproximação 
                    entre os Clientes e os Parceiros, por meio de seu site. 5.4. Ao 
                    realizar transações no site da COMPRODONTO, o Cliente autoriza, 
                    expressamente, que a <span class="uppercase">Comprodonto</span> 
                    disponibilize seus dados ao Parceiro do qual será contratado o plano 
                    odontológico pelo Cliente, para que este concretize a transação. A 
                    <span class="uppercase">Comprodonto</span> disponibilizará aos 
                    Parceiros apenas os dados pessoais necessários para a celebração do 
                    respectivo contrato, faturamento e emissão de documentos fiscais, 
                    não sendo disponibilizados outros dados pessoais ou histórico de 
                    contratações dentro do site da <span class="uppercase">Comprodonto</span>. 
                    5.5. O Usuário deverá cumprir com suas próprias obrigações tributárias, 
                    em cumprimento à legislação em vigor, não podendo imputar à 
                    <span class="uppercase">Comprodonto</span> quaisquer ônus em relação ao 
                    cumprimento destas obrigações. 5.6. O usuário se obriga, também, a 
                    pagar integralmente o preço das parcelas contratadas junto ao Parceiro. 
                    5.7. O usuário que seja menor de 18 (dezoito) anos de idade está 
                    ciente de que não poderá contratar os planos odontológicos oferecidos 
                    no site da <span class="uppercase">Comprodonto</span>, devendo a mesma 
                    ser realizada por seu responsável legal.</p>

                    <h2 class="subtitle">6. Obrigações da <span class="uppercase">Comprodonto</span></h2>
                    <p>6.1 A <span class="uppercase">Comprodonto</span> será responsável 
                    pelo desenvolvimento do site da <span class="uppercase">Comprodonto</span>, 
                    incluindo, mas não se limitando a, toda estrutura física e tecnológica 
                    para a manutenção do serviço prestado para efetivação de pedidos de 
                    contratação dos planos odontológicos anunciados pelos Parceiros. 6.2. O 
                    site <span class="uppercase">Comprodonto</span> poderá oferecer 
                    atendimento pós-venda básico, com os seguintes serviços: informações de 
                    pedido, status do pedido e demais informações de contratação junto ao 
                    Parceiro. 6.3. Quando o cancelamento da contratação for solicitado pelo 
                    usuário através do site da <span class="uppercase">Comprodonto</span>, a 
                    <span class="uppercase">Comprodonto</span> deverá endereçar referida 
                    solicitação ao respectivo Parceiro para que adote todas as providências 
                    necessárias para que o cancelamento seja feito em, no máximo, 48 
                    (quarenta e oito) horas. 6.4. A COMPRODONTO deverá proteger, por meio de 
                    armazenamento em servidores ou quaisquer outros meios magnéticos de alta 
                    segurança, a confidencialidade de todas as informações e cadastros 
                    relativos aos usuários, assim como valores atinentes às operações 
                    financeiras advindas da operacionalização dos serviços previstos no 
                    presente Termo. Contudo, não responderá pela reparação de prejuízos 
                    que possam ser derivados de apreensão e cooptação de dados por parte 
                    de terceiros que, rompendo os sistemas de segurança, consigam acessar 
                    essas informações. 6.5. Obriga-se a <span class="uppercase">Comprodonto</span> 
                    a: (i) Prestar ao Parceiro as informações possíveis e relevantes para 
                    o cumprimento do objeto do presente Termo, incluindo informações 
                    adicionais sobre o desenvolvimento do funcionamento do site da 
                    <span class="uppercase">Comprodonto</span>; (ii) Manter seu site 
                    funcionando de forma que os produtos e/ou serviços oferecidos pelos 
                    Parceiros sejam disponibilizados publicamente a todos os usuários; 
                    (iii) Manter em seu site, por si ou por terceiros, ferramentas destinadas 
                    ao registro das informações dos usuários, especialmente em relação às 
                    informações cadastrais, registro das vendas e gerenciamento de 
                    pagamentos; (iv) Utilizará seus melhores esforços para divulgar o site da 
                    <span class="uppercase">Comprodonto</span> nos canais publicitários 
                    disponíveis.</p>

                    <h2 class="subtitle">7. Obrigações dos Parceiros</h2>
                    <p>7.1 Os Parceiros somente poderão anunciar no site da 
                    <span class="uppercase">Comprodonto</span> mediante a celebração do 
                    competente contrato de prestação de serviços com a 
                    <span class="uppercase">Comprodonto</span>. 7.2. O Parceiro é o único 
                    responsável pelos produtos e/ou serviços por ele oferecidos no site da 
                    <span class="uppercase">Comprodonto</span>, bem como pelo serviço de 
                    pós-venda relacionado ao atendimento aos clientes; 7.3. A 
                    <span class="uppercase">Comprodonto</span> é a única responsável 
                    pelos danos causados a seus usuários por defeitos ou vícios 
                    relativos exclusivamente à prestação do serviço de Marketplace no 
                    site da <span class="uppercase">Comprodonto</span>, desde que a 
                    <span class="uppercase">Comprodonto</span> tenha dado causa aos 
                    referidos defeitos ou vícios. 7.4. O Parceiro concorda em manter 
                    indene a <span class="uppercase">Comprodonto</span>, seus diretores, 
                    gerentes, empregados e agentes em relação a todas e quaisquer 
                    responsabilidades, obrigações, perdas, danos, penalidades, ações, 
                    decisões, processos, custos, despesas, multas, condenações, incluindo, 
                    mas não se limitando, honorários ou desembolsos de qualquer 
                    natureza decorrentes dos produtos e serviços oferecidos pelo 
                    Parceiro por meio do site da <span class="uppercase">Comprodonto</span>, 
                    e/ou pelo descumprimento das demais obrigações deste instrumento. 7.5. 
                    Nenhuma das Partes terá qualquer tipo de responsabilidade, frente à 
                    outra Parte, por defeitos técnicos e/ou operacionais oriundos do sistema 
                    dos Clientes e/ou Usuários. 7.6. As Partes reconhecem que não é de 
                    responsabilidade da COMPRODONTO qualquer dano, prejuízo ou perda 
                    sofridos pelo Parceiro em razão de falhas na internet, no sistema ou no 
                    servidor utilizado pelo Parceiro decorrentes de condutas de terceiros, 
                    vírus ou caso fortuito e/ou força maior. 7.7. O Parceiro declara ser o 
                    único e exclusivo responsável pela existência, quantidade, qualidade, 
                    integridade ou legitimidade dos planos odontológicos por ele ofertados 
                    no site da <span class="uppercase">Comprodonto</span>, estando o usuário 
                    ciente e de acordo com o aqui estabelecido, devendo todos os planos 
                    oferecidos cumprir com as coberturas mínimas exigidas e ter sido sua 
                    comercialização autorizada pela ANS. As Partes isentam, portanto, a 
                    <span class="uppercase">Comprodonto</span> de toda e qualquer 
                    responsabilidade neste sentido. 7.8. Os Parceiros são os responsáveis 
                    pelas coberturas dos planos odontológicos comercializados através do 
                    site da <span class="uppercase">Comprodonto</span>, devendo respeitar os 
                    prazos de carência eventualmente previstos.</p>
                    
                    <h2 class="subtitle">8. Direitos Autorais - Copyright</h2>
                    <p>8.1. O Usuário e os Parceiros reconhecem e concordam que todo o 
                    serviço, bem como quaisquer marcas, nomes comerciais e produtos 
                    apresentados no site da <span class="uppercase">Comprodonto</span> 
                    estão protegidos por todas as leis e tratados aplicáveis no Brasil e/ou 
                    exterior. Dessa forma, a <span class="uppercase">Comprodonto</span> é 
                    a legítima detentora dos respectivos direitos autorais (copyright), 
                    sendo, portanto, vedada à utilização do conteúdo do site da 
                    <span class="uppercase">Comprodonto</span> sem a expressa e prévia 
                    autorização por escrito pela <span class="uppercase">Comprodonto</span>. 
                    8.2. O Usuário e os Parceiros concordam com a utilização de suas 
                    informações pessoais obtidas através de comentários e/ou avaliações 
                    tanto do site da <span class="uppercase">Comprodonto</span> quanto dos Parceiros para divulgação do 
                    site <span class="uppercase">Comprodonto</span> junto aos meios de 
                    publicidade, não sendo devida qualquer compensação financeira pela 
                    <span class="uppercase">Comprodonto</span> ou pelos Parceiros aos usuários.</p>
                    
                    <h2 class="subtitle">9. Modificações deste Termo</h2>
                    <p>9.1. O presente Termo poderá, a qualquer tempo, ter seu conteúdo, 
                    ou parte dele, modificados para adequações e inserções, tudo com 
                    vistas ao aprimoramento dos serviços disponibilizados. 
                    O novo Termo entrará em vigor após 10 (dez) dias contados de sua 
                    publicação no site da <span class="uppercase">Comprodonto</span>. 
                    9.2 As novas condições entrarão em vigência assim que sua veiculada 
                    no site, sendo possível ao usuário manifestar oposição a quaisquer 
                    dos termos modificados, desde que o faça por escrito, através do 
                    site da <span class="uppercase">Comprodonto</span>, o que gerará o 
                    cancelamento de seu cadastro. 9.2.1. A 
                    <span class="uppercase">Comprodonto</span> se compromete a comunicar 
                    os usuários sobre eventuais alterações desse Termo através do e-mail 
                    cadastrado no site da <span class="uppercase">Comprodonto</span>, 
                    sendo que a vigência do novo Termo respeitará o prazo previsto no 
                    item 9.1, independente da data de recebimento do e-mail pelo usuário.</p>
                    
                    <h2 class="subtitle">10. Lei aplicável e foro de eleição</h2>
                    <p>Estes Termos e Condições de Uso do site da 
                    <span class="uppercase">Comprodonto</span> são regidos pelas Leis do 
                    Brasil. Para todas as controvérsias e questões relacionadas e estes 
                    Termos e Condições as partes elegem o foro da Comarca de Poços de 
                    Caldas/MG. 11. Dúvidas 11.1. Qualquer dúvida ou eventuais problemas 
                    quanto a este Termo deverão ser encaminhadas diretamente em campo 
                    adequado disponível na página eletrônica www.comprodonto.com.br, 
                    através do e-mail: contato@comprodonto.com.br que serão respondidas de 
                    maneira objetiva a tentar sanar qualquer questão de usabilidade.</p>
                </div>
                <div class="footer">
                    <Button type="fechar-modal" text="Fechar" :click="closeModal" />
                </div>
            </div>





            <!-- Politica de Privacidade -->


            <div class="modal-container" v-else-if="store.getters.modalTermsAndPolitics.content === 'politics'">
                <div class="header">
                    <h1 class="title-modal">Políticas de Privacidade</h1>
                </div>
                <div class="body">
                    <h1 class="title uppercase">Política de Privacidade</h1>
                    <p>A <span class="uppercase">Comprodonto</span> está comprometida em 
                    resguardar a privacidade de seus usuários. O objetivo dessa política 
                    é esclarecer quais dados e informações são coletadas dos usuários 
                    através da utilização do site da COMPRODONTO (www.comprodonto.com.br), 
                    e de que forma essas informações são obtidas, usadas, divulgadas, 
                    transferidas e/ou armazenadas. Essa política de privacidade é parte 
                    integrante dos Termos de Uso do site da 
                    <span class="uppercase">Comprodonto</span>. É importante destacar que 
                    não faça uso de nossos serviços caso não concorde com o conteúdo 
                    dessa política.</p>

                    <h2 class="subtitle">1. Coleta de dados e informações pessoais</h2>
                    <p>1.1. A coleta de dados dos internautas e dos usuários, tais como 
                    endereço de IP (internet protocol) ou ID de celulares, tipo de navegador, 
                    idioma, tempo de acesso e pesquisas são automaticamente coletadas e gravadas, 
                    bem como as sugestões, perguntas ou reclamações.</p> 
                    <p>1.2. As informações pessoais serão coletadas para a efetivação do cadastro dos usuários 
                    nos servidores do site da <span class="uppercase">Comprodonto</span>, sendo consideradas como 
                    informações pessoais qualquer dado que possa ser utilizado para 
                    identificar individualmente ou entrar em contato com uma pessoa em 
                    específico, não se limitando a nome, e-mail e/ou| número de documento.</p> 
                    <p>1.3. O usuário poderá ser solicitado a fornecer suas informações 
                    pessoais a qualquer momento, enquanto estiver em contato com o site da 
                    <span class="uppercase">Comprodonto</span> ou com os Parceiros. A 
                    <span class="uppercase">Comprodonto</span> e os Parceiros poderão 
                    compartilhar essas informações pessoais e utilizá-las de acordo com 
                    esta Política de Privacidade. A <span class="uppercase">Comprodonto</span> 
                    e os Parceiros poderão, ainda, combinar essas informações pessoais com 
                    outras informações para fornecer e aperfeiçoar nossos produtos, serviços, 
                    conteúdo e publicidade.</p>

                    <h2 class="subtitle">2. Utilização dos dados pessoais coletados</h2>
                    <p>2.1. As informações pessoais serão mantidas sob o mais completo 
                    sigilo pela <span class="uppercase">Comprodonto</span>, vinculando-se 
                    unicamente ao seu registro e utilizando-as unicamente para:</p>
                    <ol type="a">
                        <li>Realizar a entrega de documentação e a cobrança dos planos odontológicos
                        contratados através do site da <span class="uppercase">Comprodonto</span>, 
                        incluindo os produtos e serviços ofertados pelos Parceiros, bem como a 
                        identificação do respectivo destinatário;</li>
                        <li>Cumprir obrigações legais de qualquer natureza;</li>
                        <li>Obter estatísticas genéricas para identificação do perfil dos nossos usuários 
                        e desenvolvimento de nossas campanhas;</li>
                        <li>Manutenção de dados e ações de relacionamento com o usuário, bem como a 
                        melhoria contínua do serviço prestado pela <span class="uppercase">Comprodonto</span>;</li>
                        <li>Resolução de eventuais questões legais relacionadas ao site da <span class="uppercase">Comprodonto</span>;</li>
                        <li>Melhoria da experiência do usuário no site da <span class="uppercase">Comprodonto</span>, 
                        por meio de ofertas específicas e direcionadas, além da prática de 
                        marketing direcionado, de acordo com o seu perfil;</li>
                        <li>Fornecer, manter, proteger, desenvolver e melhorar o site da 
                        <span class="uppercase">Comprodonto</span> e sua segurança;</li>
                        <li>Enviar comunicados aos usuários sobre ofertas, novidades ou novos serviços 
                        próprios e de terceiros da <span class="uppercase">Comprodonto</span>;</li>
                        <li>Manter atualizados os cadastros dos usuários para fins de contato telefônico, por 
                        correio eletrônico, via mala direta, SMS ou outros meios de comunicação;</li>
                        <li>Aperfeiçoar a usabilidade e a experiência interativa durante a navegação do usuário 
                        no Portal;</li>
                        <li>Elaborar estatísticas gerais, sem que haja identificação dos usuários;</li>
                        <li>Garantir a segurança dos Internautas;</li>
                        <li>Responder a suas eventuais dúvidas ou solicitações;</li>
                        <li>Armazenar as preferências dos usuários;</li>
                        <li>Constituir, defender ou exercer regularmente direitos em âmbito judicial 
                        ou administrativo;</li>
                        <li>Realizar pesquisas e campanhas de comunicação e marketing de relacionamento;</li>
                        <li>Prestar todos os serviços ofertados em seu site, nas condições previstas 
                        nos Termos e Condições de Uso do site da <span class="uppercase">Comprodonto</span>.</li>
                    </ol>

                    <p>2.2. As informações solicitadas aos usuários são necessárias para melhorar 
                    e personalizar a experiência de compra junto ao site da 
                    <span class="uppercase">Comprodonto</span>. Essas informações não serão 
                    utilizadas além das hipóteses previstas neste documento e qualquer uso 
                    estará sempre vinculado com as diretrizes traçadas pela 
                    <span class="uppercase">Comprodonto</span> nesta Política de Privacidade.</p>
                    
                    <p>2.3. O usuário garante a veracidade e exatidão das informações e dados 
                    que fornecer ao site da <span class="uppercase">Comprodonto</span>, 
                    assumindo a correspondente responsabilidade pela sua inexatidão, bem 
                    como se compromete a mantê-los devidamente atualizadas. O site da 
                    <span class="uppercase">Comprodonto</span> não assume qualquer 
                    responsabilidade em caso de danos causados pela inexatidão dos dados 
                    informados.</p>

                    <p>2.4. O usuário que se cadastrar no site da 
                    <span class="uppercase">Comprodonto</span> utilizando dados falsos ou 
                    de terceiros poderá incorrer nos crimes de falsidade ideológica, 
                    estelionato ou outros delitos mais graves, podendo responder civil ou 
                    criminalmente por tal conduta, nos termos da lei.</p>

                    <p>2.5. O usuário poderá, a qualquer momento, solicitar alteração, 
                    retificação e/ou exclusão de seus dados, bastando para tanto, 
                    utilizar sua Conta de Acesso no site da 
                    <span class="uppercase">Comprodonto</span> ou através dos canais de 
                    contatos informados nessa Política de Privacidade.</p>

                    <p>2.6. A <span class="uppercase">Comprodonto</span> poderá compartilhar 
                    com terceiros as informações pessoais e informações de acesso para 
                    prestação dos serviços de Marketplace da 
                    <span class="uppercase">Comprodonto</span>. Para tanto, a 
                    <span class="uppercase">Comprodonto</span> compartilhará as informações 
                    de seus usuários com Parceiros na medida necessária para que estes 
                    terceiros possam cumprir integralmente com suas obrigações decorrentes 
                    de Lei e dos Termos e Condições de Uso do site da 
                    <span class="uppercase">Comprodonto</span>.</p>
                    
                    <p>2.7. O usuário tem ciência e concorda que para fins de prestação de 
                    serviços e entrega dos produtos e serviços ofertados pelos Parceiros 
                    e/ou pelo site da <span class="uppercase">Comprodonto</span>, pode ser 
                    necessária a contratação de terceiros, que terão acesso às informações 
                    e dados do usuário exclusivamente para os fins necessários.</p>

                    <p>2.8. A <span class="uppercase">Comprodonto</span> poderá também 
                    compartilhar com terceiros as informações pessoais e informações de 
                    acesso dos usuários para prestação dos serviços oferecidos pela 
                    <span class="uppercase">Comprodonto</span>.</p>

                    <p>2.9. A <span class="uppercase">Comprodonto</span> também poderá compartilhar 
                    com terceiros as informações pessoais e informações de acesso para 
                    cumprir qualquer lei, regulamentação, determinação judicial, solicitação 
                    governamental, ou para detectar, impedir ou abordar de qualquer forma 
                    questões técnicas, de fraude ou de segurança, proteger os direitos, 
                    as propriedades ou a segurança da 
                    <span class="uppercase">Comprodonto</span>, do site da 
                    <span class="uppercase">Comprodonto</span>, seus usuários, parceiros ou 
                    quaisquer terceiros.</p>

                    <p>2.9.1. Nesse sentido, a <span class="uppercase">Comprodonto</span> 
                    informa que está legalmente obrigado a divulgar os dados cadastrais 
                    que informem qualificação pessoal, filiação e endereço às autoridades 
                    administrativas legalmente habilitadas, tais como os Delegados da 
                    Polícia Civil e o Ministério Público, nos termos do artigo 10, § 3º, 
                    da Lei 12.965/14.</p>

                    <h2 class="subtitle">3. Proteção e privacidade das informações coletadas</h2>
                    <p>3.1. A <span class="uppercase">Comprodonto</span> fará todo o possível para manter a privacidade das 
                    informações pessoais e informações de acesso armazenados em seus servidores, 
                    comprometendo-se a utilizar tecnologia que seja suficientemente adequada para a 
                    proteção de tais dados, procurando manter o ambiente seguro, com uso de ferramentas 
                    apropriadas e controles eficientes de segurança das informações coletadas, sempre 
                    observando o estado da técnica disponível. Contudo, considerando que nenhum sistema 
                    de segurança é absolutamente seguro, <span class="uppercase">Comprodonto</span> se exime de quaisquer 
                    responsabilidades por eventuais danos e/ou prejuízos decorrentes de invasões no site da 
                    <span class="uppercase">Comprodonto</span>, em seus servidores e demais falhas relacionadas, salvo se houver 
                    dolo ou culpa da <span class="uppercase">Comprodonto</span>;</p>
                    
                    <p>3.2. Os profissionais da <span class="uppercase">Comprodonto</span> têm conhecimento da presente Política de 
                    Privacidade e apenas o pessoal qualificado e autorizado tem permissão para acessar as 
                    informações coletadas pela <span class="uppercase">Comprodonto</span>, sob pena de sofrerem sanção 
                    disciplinar em caso de violação das normas aqui previstas;</p>
                    
                    <p>3.3. As empresas parceiras e prestadores de serviços da <span class="uppercase">Comprodonto</span> tem ciência 
                    e estão comprometidas em respeitar a proteção e privacidade de dados assegurados 
                    nesta política, quando houver necessidade de compartilhamento destes dados, nos 
                    termos desta política;</p>

                    <p>3.4. O usuário reconhece e concorda que a 
                    <span class="uppercase">Comprodonto</span> pode disponibilizar 
                    links para outros sites que, por sua vez, podem coletar 
                    informações pessoalmente identificáveis do usuário. O 
                    usuário reconhece e concorda que a COMPRODONTO não é responsável pelas 
                    práticas de privacidade de outros sites; e é recomendado ao Internauta 
                    ler atentamente as declarações de privacidade de todo e qualquer site 
                    que colete informações pessoalmente identificáveis. Esta Política de 
                    Privacidade se aplica somente às informações coletadas e processadas 
                    pela <span class="uppercase">Comprodonto</span> e pelo site da 
                    <span class="uppercase">Comprodonto</span>;</p>

                    <p>3.5. A <span class="uppercase">Comprodonto</span> declara que respeita toda 
                    e qualquer legislação vigente sobre proteção de dados e privacidade dos 
                    nossos usuários;</p>

                    <p>3.6. A <span class="uppercase">Comprodonto</span> possui sistema moderno de 
                    segurança de decodificação através de criptografia devidamente validado 
                    pelas autoridades certificadoras e também trabalha com um firewall com 
                    o objetivo de impedir o acesso a seus servidores por pessoas não 
                    autorizadas.</p>

                    <h2 class="subtitle">4. Envio de comunicados</h2>
                    <p>4.1. A seu exclusivo critério, a <span class="uppercase">Comprodonto</span> 
                    poderá enviar comunicados e mensagens publicitárias diariamente aos usuários 
                    cadastrados no site da <span class="uppercase">Comprodonto</span>, ou 
                    Internautas em geral, fazendo uso de todos os tipos de tecnologias e 
                    meios de comunicação disponíveis, seja por e-mail, SMS, MMS, mala- direta 
                    ou outros;</p>
                    <p>4.2. Os comunicados, newsletters, avisos de ofertas e mensagens 
                    publicitárias enviadas por e-mail trarão, obrigatoriamente, opção de 
                    cancelamento (opt-out) do envio daquele tipo de mensagem por parte do 
                    site da <span class="uppercase">Comprodonto</span>. O cancelamento do 
                    envio também pode ser solicitado pelos canais de atendimento disponíveis 
                    no site da <span class="uppercase">Comprodonto</span>;</p>

                    <p>4.2.1. A <span class="uppercase">Comprodonto</span> se esforçará para atender 
                    a solicitação no menor tempo possível. No entanto, tendo em vista o 
                    procedimento envolve atualização das bases de dados da 
                    <span class="uppercase">Comprodonto</span> e eventualmente de empresas 
                    terceiras, o usuário compreende que a solicitação poderá demorar alguns 
                    dias até ser concretizada;</p>

                    <p>4.3. O processo de cancelamento de envio é vinculado ao e-mail do usuário. 
                    Portanto usuários que possuam mais de um e-mail cadastrado poderão 
                    continuar a receber os comunicados ou as mensagens publicitárias no 
                    e-mail que não foi descadastrado;</p>

                    <p>4.4. Os serviços de envio de e-mails podem ser realizados pela 
                    <span class="uppercase">Comprodonto</span> ou por empresa contratada, 
                    que utilizará seus próprios servidores para realizar o envio;</p>
                   
                    <p>4.5. A empresa contratada não armazena nem utiliza de nenhuma forma 
                    os e-mails de cadastro do site da <span class="uppercase">Comprodonto</span> 
                    para qualquer outro fim que não o envio das mensagens, de acordo com as preferências 
                    de cada usuário e as disposições previstas na presente Política de Privacidade e nos 
                    Termos e Condições de Uso do site da 
                    <span class="uppercase">Comprodonto</span>.</p>

                    <h2 class="subtitle">5. Cookies e informações sobre localização</h2>
                    <p>5.1. Ao visitar o site da <span class="uppercase">Comprodonto</span>, 
                    podemos enviar cookies para seu computador objetivando facilitar nosso 
                    site ou dar acesso a recursos adicionais e acompanhamento de atividade 
                    de marketing. Os cookies nos permitem coletarmos suas atividades online, 
                    tais como a visitação de páginas virtuais, sendo de responsabilidade 
                    dos usuários desativarem os cookies;</p>

                    <p>5.2. Ao começar a utilizar o site da 
                    <span class="uppercase">Comprodonto</span>, podemos pedir sua 
                    autorização para ter acesso a sua localização, que será obtida pelo 
                    site em caso de anuência. Usaremos essas informações para poder tornar 
                    as funcionalidades de acordo com a sua localização, informando sobre 
                    eventuais informações sobre produtos e/ou serviços próximos à sua 
                    localização. Podemos armazenar esses dados, porém não será associado 
                    a sua pessoa, permanecendo o usuário anônimo.</p>

                    <h2 class="subtitle">6. Modificações dessa Política de Privacidade</h2>
                    <p>6.1. A presente Política de Privacidade está sujeita a constante melhoria e 
                    aprimoramento para garantir mais transparência e segurança aos 
                    Internautas. Assim, a <span class="uppercase">Comprodonto</span> se 
                    reserva o direito de modificar a qualquer momento, de forma unilateral, 
                    o presente documento. A nova Política de Privacidade entrará em vigor 
                    após 10 (dez) dias contados de sua publicação no site da 
                    <span class="uppercase">Comprodonto</span>;</p>

                    <p>6.1.1. A <span class="uppercase">Comprodonto</span> se compromete a 
                    comunicar os usuários sobre eventuais alterações dessa Política de 
                    Privacidade através do e-mail cadastrado no site da 
                    <span class="uppercase">Comprodonto</span>, sendo que a vigência da 
                    nova política respeitará o prazo previsto no item 6.1, independente da 
                    data de recebimento do e-mail pelo usuário;</p>

                    <p>6.2. Ao continuar acessando o site da COMPRODONTO após a entrada em 
                    vigor das modificações, o usuário automaticamente concorda e aceita o 
                    teor das modificações realizadas. Portanto, entende que deve verificar 
                    regularmente eventuais mudanças ao presente documento.</p>

                    <h2 class="subtitle">7. Lei aplicável</h2>
                    <p>7.1. A presente Política de Privacidade é regida e interpretada 
                    segundo as leis da República Federativa do Brasil.</p>

                    <h2 class="subtitle">8. Dúvidas</h2>
                    <p>8.1. Qualquer dúvida ou eventuais problemas quanto a esta política de 
                    privacidade deverão ser encaminhadas diretamente em campo adequado disponível 
                    na página eletrônica www.comprodonto.com.br, através do 
                    e-mail: contato@comprodonto.com.br que serão respondidas de maneira objetiva a 
                    tentar sanar qualquer questão de usabilidade;</p>

                    <p>8.2. Os Parceiros possuem canais e horários de atendimento próprios que são 
                    divulgados aos usuários antes da finalização da aquisição do produto ou 
                    serviço. Política de Privacidade elaborada em 02 de janeiro de 2019.</p>
                </div>
                <div class="footer">
                    <Button type="fechar-modal" text="Fechar" :click="closeModal" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import Button from './Button.vue';

export default {
    name: "ModalTermsAndPolitics",
    components:{
        Button
    },
    setup() {
        const store = useStore();
        const closeModal = () => store.commit("setModalTermsAndPolitics", {display: false, content: ''});

        return { closeModal, store }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        height: 60vh;
        overflow-y: auto;
        padding: 1rem;
        text-align: left;
        color: #50596C;
        font-size: .9765625rem;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        z-index: 999999;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 90vw;
        height: 83vh;
        margin: auto;
        z-index: 9999999 !important;
        text-align: justify;
    }

    .modal-container p:last-child{
        padding-bottom: 2rem;
    }

    .header{
        background-color: #c90c53;
        color: #fff; 
        padding: 1rem;
    }

    .title-modal{
        margin: 0;
        font-size: 1.71875rem;
        text-align: left;
    }

    .title{
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    .title-ul{
        display: inline-block;
        transform: translateX(-1.3rem);
        margin-top: .8rem;
    }

    .subtitle{
        font-size: .9765625rem;
        font-weight: 600;
    }
    .subtitle-comprodonto{
        font-size: .9765625rem;
        font-weight: 600;
        margin: 1.5rem 0 2rem 0;
    }
    .subtitle-politics{
        font-size: 1rem;
        font-weight: 600;
        margin: 1.2rem 0;
    }

    .emphasis{
        text-transform: uppercase;
        font-weight: 700;
    }

    .uppercase{
        text-transform: uppercase;
    }

    .weight{
        font-weight: 600;
    }

    .underline{
        text-decoration: underline;
    }

    .margin{
        display: inline-block;
        margin: 1rem 0 0 0;
    }

    @media screen and (min-width: 690px) {

        .body {
            height: 60vh;
        }

        .modal-container{
            width: 70vw;
        }
    }

    @media screen and (min-width: 1025px) {

        .body {
            height: 70vh;
        }

        .modal-container{
            height: 93vh;
            width: 50%;
            max-height: none;
        }
    }


</style>
