<template>
    <div :class="`${isMobile ? 'container-meusdados-mobile' : 'container-meusdados'}`">
        <h1 v-if="!isMobile" class="title">Meus dados</h1>
        <div :class="`${isMobile ? 'containerCards-mobile' : 'containerCards'}`">
            <CardTitular :isMobile="isMobile" :data="detalhesData" />
            <CardCartao :isMobile="isMobile" :data="detalhesData" v-if="detalhesData.cartao.numbers" />
        </div>
    </div>
</template>

<script>
import CardTitular from './CardTitular.vue';
import CardCartao from './CardCartao.vue';
import { useStore } from 'vuex';
export default {
    name: "MeusDados",
    props: {
        detalhesData: Object,
        isMobile: Boolean
    },
    components: {
        CardTitular,
        CardCartao,
    },
    setup() {
        const store = useStore();

        return { store }
    },
}
</script>

<style scoped>
    .container-meusdados-mobile{
        padding: 0;
        width: 100%;
    }
    .container-meusdados{
        padding: 10rem 0 0 6rem;
        width: calc(100% - 14.5rem);
    }

    .containerCards-mobile{
        width: 100%;
        display: flex;
        flex-flow: column;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .containerCards{
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-bottom: 4rem;
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 2.1875rem 0;
        text-align: left;
    }
</style>