<template>
    <div class="background"> 
        <h1>Ops, parece que essa página não existe.</h1>

        <router-link to="/">
          <button class="btn-cadastro" type="button">Ir para tela inicial</button>
        </router-link>
    </div>
</template>
<script>
export default {
    name: '404'
}
</script>
<style scoped>
.background{
  background-color: #c90c53;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-cadastro{
  background-color: #fff;
  color: #c90c53;
  border: none;
  font-size: 1.125rem;
  padding: .5rem 2rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1{
  font-size: 5em;
  font-weight: bold;
  color: white;
  width: 80%;
  margin-bottom: 5rem;
}

@media screen and (max-width: 800px) {
  h1{
    font-size: 2.5em;
    font-weight: bold;
    color: white;
    width: 80%;
    margin-bottom: 5rem;
  }
}
</style>