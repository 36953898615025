<template>
    <div :class="`${isMobile ? 'detalhes-plano-container-mobile' : 'detalhes-plano-container'}`">
        <div class="goBack" v-if="isMobile && store.getters.hasDetalhesPlano" @click="goBack">
            <span class="material-icons-outlined iconBack">navigate_before</span>
            {{ data.subscriptions[0].dependentes[index].name }}
        </div>

        <div :class="`${isMobile ? 'coluna-1-mobile' : 'coluna-1'}`">
            <div :class="`${isMobile ? 'card-container-mobile' : 'card-container'}`" @click="click">
                <div class="imgContainer">
                    <img :src="data.subscriptions[0].dependentes[index].foto_plano ? readURL(data.subscriptions[0].dependentes[index].foto_plano) : 'https://via.placeholder.com/100x100'" alt />
                </div>
                <div class="planDetails">
                    <h3 class="planName">{{ data.subscriptions[0].dependentes[index].name }}</h3>
                    <span v-if="status === 'Ativo'" class="status bg-active">{{ status }}</span>
                    <span v-else-if="status === 'Aguardando Pagamento'" class="status bg-pending">{{ status }}</span>
                    <span v-else class="status bg-canceled">{{ status }}</span>
                    <p class="active-date">Data de Ativação: {{ toDate(data.subscriptions[0].created_at)}}</p>
                    <a class="cancel-plan" @click="cancelPlan">Cancelar o plano</a>
                </div>
            </div>

            <div v-if="isMobile" class="subscriber-container-mobile">
                <div class="subscriber-header">
                    <span class="material-icons-outlined iconUser">person_outline</span>
                    <h2 class="subtitle">Beneficiário do plano</h2>
                </div>
                <div class="subscriber-info-mobile">
                    <p>{{ data.subscriptions[0].dependentes[index].nome_dependente }}</p>
                    <p>CPF - {{ numberToCpf(data.subscriptions[0].dependentes[index].cpf) }}</p>
                    <p>Idade - {{ age }} anos</p>
                    <p>Parentesco - {{ data.subscriptions[0].dependentes[index].parentesco }}</p>
                </div>
            </div>

            <div :class="`${isMobile ? 'pos-venda-container-mobile' : 'pos-venda-container'}`">
                <h1 class="pos-venda-title">Pós Vendas</h1>
                <div class="pos-venda-text" v-html="data.subscriptions[0].plan.provider.texto_email_pos_venda"></div>
            </div>
        </div>

        <div :class="`${isMobile ? 'coluna-2-mobile' : 'coluna-2'}`">
            <div v-if="!isMobile" class="subscriber-container">
                <div class="subscriber-header">
                    <span class="material-icons-outlined iconUser">person_outline</span>
                    <h2 class="subtitle">Beneficiário do plano</h2>
                </div>
                <div class="subscriber-info">
                    <p>{{ data.subscriptions[0].dependentes[index].nome_dependente }}</p>
                    <p>CPF - {{ numberToCpf(data.subscriptions[0].dependentes[index].cpf) }}</p>
                    <p>Idade - {{ age }} anos</p>
                    <p>Parentesco - {{ data.subscriptions[0].dependentes[index].parentesco }}</p>
                </div>
            </div>

            <div class="doc-container">
                <h3 class="subtitle">Documentação</h3>
                <ul class="list">
                    <li v-for="(arquivo, index) in data.subscriptions[0].arquivos" :key="index" class="item-list">
                        <a :href="arquivo.path" target="_blank">{{ arquivo.nome }}</a>
                    </li>
                </ul>
            </div>

            <div class="area-container">
                <h3 class="subtitle">Área de Cobertura</h3>
                <ul class="list">
                    <li v-for="(area, index) in abrangencia" :key="index" class="item-list">
                        {{ area.nome }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import { createStatusPlanBadge, numberToCpf, toDate } from '../../../services/formater';
import { useStore } from 'vuex';
import { GET } from '../../../services/api';
export default{
    name: "DetalhesPlano",
    props: {
        data: Object,
        index: Number || String,
        isMobile: Boolean
    },
    async created() {
        const alert = inject("alert");
        const loader = inject("loading");
        loader.open();
        this.status = createStatusPlanBadge(this.data.subscriptions[0].dependentes[this.index].status);
        this.age = new Date().getFullYear() - Number(this.data.subscriptions[0].dependentes[this.index].birth_date.split('-')[0]);
        try{
            const response = await GET(`detalhes/plano/${this.data.subscriptions[0].dependentes[this.index].id}`);
            this.abrangencia = response.abrangencia
        } catch (e) {
            alert.open("Erro", e.message);
        }
        loader.close();
    },
    setup(props) {
        const store= useStore();
        const alert = inject("alert");

        const status = ref('');
        const age = ref('');
        const abrangencia = ref([]);
        
        const readURL = file => {
            if(typeof(file) == 'string'){
                return file
            }
            return window.URL.createObjectURL(file)
        };

        const goBack = () => {
            store.commit("setDetalhesPlano", false);
            window.scrollTo(0,0);
        };

        const cancelPlan = () => {
            let info = props.data.subscriptions[0].plan.provider;
            alert.open("Atenção", `Para cancelamento do plano, ligue para ${info.telefone} ou envie um e-mail para ${info.email}` );
        };

        return { readURL, status, toDate, numberToCpf, age, abrangencia, store, goBack, cancelPlan }
    },
}
</script>

<style scoped>
    .detalhes-plano-container-mobile{
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .detalhes-plano-container{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .goBack{
        position: fixed;
        top: 94px;
        z-index: 90;
        margin-bottom: 1.5rem;
        width: 119%;
        padding: 1rem 0;
        background-color: #00FFF5;
        font-weight: 700;
        color: #000;
        font-size: 1.375rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .iconBack{
        font-weight: 700;
        color: #000;
        margin-left: 10%;
        margin-right: 1.5rem;
    }

    .coluna-1-mobile{
        width: 100%;
        margin-top: 12rem;
    }
    .coluna-1{
        width: 53%;
    }

    .coluna-2-mobile{
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        padding-bottom: 4rem;
        transform: translateY(-8rem);
    }
    .coluna-2{
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        padding-bottom: 4rem;
    }

    .card-container-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 28rem;
        height: 9.6875rem;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #fff;
        padding: 1.3125rem 1.6875rem 1rem 1.6875rem;
        border-radius: 5px;
        margin: 0 auto 2rem auto;
    }
    .card-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 25rem;
        height: 9.6875rem;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #fff;
        padding: 1rem 1.3rem 1rem 1.3rem;
        border-radius: 5px;
        margin-bottom: 2rem;
    }

    .imgContainer{
        display: flex;
        justify-content: flex-start;
        width: 11rem;
    }

    .imgContainer img{
        object-fit: cover;
        width: 100%;
    }

    .planName{
        display: block;
        color: #000;
        font-size: .9375rem;
        font-weight: 700;
        margin: 5px 0;
        text-transform: initial;
        text-align: center;
    }

    .planDetails{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 58%;
        margin-left: .25rem;
    }

    .status{
        min-width: 60%;
        height: auto;
        border-radius: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
        padding: .2rem .25rem;
        background-color: #17d266;
        margin: .25rem 0;
        text-align: center;
    }

    .bg-active{
        background-color: #17d266;
    }
    .bg-pending{
        background-color: #f0730d;
        font-size: .7rem;
        line-height: .8rem;
    }
    .bg-canceled{
        background-color: #d21717;
    }

    .active-date{
        color: #747474;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1.25rem;
        display: block;
        margin-bottom: .625rem;
    }

    .cancel-plan{
        text-decoration: none;
        font-size: .75rem;
        cursor: pointer;
    }

    .pos-venda-container-mobile{
        text-align: left;
        width: 100%;
    }
    .pos-venda-container{
        text-align: left;
    }

    .pos-venda-title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .subscriber-container-mobile{
        width: 100%;
        max-width: 28rem;
        border: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 2rem;
        margin: 0 auto 2rem auto;
    }
    .subscriber-container{
        width: 18.75rem;
        padding-bottom: 2.4375rem;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        padding-top: 1.875rem;
        padding-left: 2.4375rem;
        padding-right: 2.4375rem;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .subtitle{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 400;
        line-height: .9375rem;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
    }

    .subscriber-header{
        display: flex;
        align-items: center;
    }

    .subscriber-info-mobile p{
        margin-bottom: .2rem
    }

    .subscriber-info-mobile{
        color: #747474;
        font-size: .875rem;;
        font-weight: 400;
        line-height: 1.5rem;
        margin-top: 1rem;
        margin-left: .5rem;
    }
    .subscriber-info{
        color: #747474;
        font-size: .875rem;;
        font-weight: 400;
        line-height: 1.5rem;
        margin-top: 1.0625rem;
        margin-left: 3rem;
    }

    .iconUser{
        color: #D30C53;
        font-size: 2rem;
        margin-right: .8rem;
        align-self: flex-end;
    }

    .doc-container{
        margin-top: 1.75rem;
        width: 18.75rem;
        height: auto;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2.375rem;
        padding-right: .625rem;
        text-align: left;
    }

    .list{
        color: #747474;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        margin-top: .8rem;
        margin-left: 1.2rem;
    }

    .item-list{
        text-align: left;
    }

    .item-list a{
        text-decoration: none;
        color: #747474;
    }

    .area-container{
        margin-top: 1.75rem;
        width: 18.75rem;
        height: auto;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2.375rem;
        padding-right: .625rem;
    }

    @media screen and (max-width: 440px) {
        .card-container-mobile{
            padding: 1rem;
        }

        .active-date{
            text-align: center;
        }

        .planName{
            text-align: center;
        }
    }
</style>
