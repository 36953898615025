<template>
    <div v-if="!isMobile" class="sidebar-container">
        <div  class="sidebar-header">
            <span class="material-icons-outlined iconUser">person_outline</span>
            <span class="user">Olá {{ `${user.name} ${user.last_name}` }}</span>
        </div>
        <ul class="options-container">
            <template v-for="option in options" :key="option">
                <li 
                    v-if="option.label !== 'Sair'"
                    @click="() => {
                        checkItem(option.id);
                        getCurrentSection(option.id)
                    }"
                    class="option-container"
                >
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    <p :class="`${option.active ? 'option active' : 'option'}`">{{ option.label }}</p>
                </li>
                <li 
                    v-else
                    @click="logout" 
                    class="option-container" 
                >
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    <p :class="`${option.active ? 'option active' : 'option'}`">{{ option.label }}</p>
                </li>
            </template>
        </ul>
    </div>
    <div v-else class="sidebar-container-mobile">
        <div class="sidebar-header-mobile col-12" @click="showItemsMenu">
            <span class="user-mobile">{{ currentSection }}</span>
            <span v-if="hasItemsMenu" class="material-icons-outlined icon animate__animated animate__rotateIn">expand_less</span>
            <span v-else class="material-icons-outlined icon animate__animated animate__rotateIn">expand_more</span>
        </div>
        <ul v-if="hasItemsMenu" class="options-container-mobile col-12 mx-auto animate__animated animate__fadeInDown">
            <template v-for="option in options" :key="option">
                <li 
                    v-if="option.label !== 'Sair'"
                    @click="() => {
                        checkItem(option.id);
                        getCurrentSection(option.id)
                    }" 
                    class="option-container-mobile" 
                >
                    <p :class="`${option.active ? 'option-mobile active' : 'option-mobile'}`">{{ option.label }}</p>
                </li>
                <li 
                    v-else
                    @click="logout" 
                    class="option-container-mobile" 
                >
                    <p :class="`${option.active ? 'option-mobile active' : 'option-mobile'}`">{{ option.label }}</p>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { deleteCookie } from '../../services/util';
export default {
    name: "SidebarPerfil",
    props: {
        user: Object,
        isMobile: Boolean,
        boleto: Boolean
    },
    created() {
        if (!this.boleto) {
            this.options = [
                {label: 'Meus Planos', id: 'planos', active: true},
                {label: 'Meus Dados', id: 'dados',  active: false},
                {label: 'Minhas Faturas', id: 'faturas',  active: false},
                {label: 'Sair', id: 'logout'},
            ]
        } else {
            this.options = [
                {label: 'Meus Planos', id: 'planos', active: true},
                {label: 'Meus Dados', id: 'dados',  active: false},
                {label: 'Sair', id: 'logout'},
            ]
        }
        this.currentSection = this.options[0].label;
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loader = inject("loading");
        const shoppingCar = inject("shoppingCar");
        const modal = inject("modal");

        const options = ref([]);
        const hasItemsMenu = ref(false);
        const currentSection = ref("");

        const checkItem = (optionId) => {
            options.value.map( option => {
                option.active = false;
                option.id === optionId && (option.active = true);
            })
        };

        const getCurrentSection = (optionId) => {
            store.commit("setSectionMinhaArea", optionId);
            options.value.map( option => option.id === optionId && (currentSection.value = option.label));
            hasItemsMenu.value = false;
            store.commit("setDetalhesPlano", false);
            if (optionId === 'dados') {
                router.push({ name: 'MeusDados' });
            }
            };

        const showItemsMenu = () => hasItemsMenu.value = !hasItemsMenu.value;

        const logout = () => {
            modal.open(
                "Atenção!",
                'Deseja fazer logout agora?',
                'Confirmar',
                'Cancelar',
                () => {
                    loader.open();
                    shoppingCar.finish();
                    localStorage.clear();
                    const domain = process.env.NODE_ENV === 'production' ? "www.comprodonto.com.br" : window.location.hostname;
                    deleteCookie("lead_id_cookie", "/", domain);

                    store.commit("setTextSidebar", "Login");
                    setTimeout( () => {
                        router.push("/");
                        loader.close();
                    }, 1000);
                },
                () => modal.close()
            )
        };

        return { 
            checkItem, 
            options, 
            hasItemsMenu, 
            showItemsMenu, 
            currentSection, 
            getCurrentSection,
            logout
        }
    },
}
</script>

<style scoped>
    .sidebar-container-mobile{
        position: relative;
        padding-top: 95px;
        width: 100%;
    }
    .sidebar-container{
        padding-top: 10rem;
        min-height: 100vh;
        width: 14.5rem;
        border-right: 1px solid #ddd;
    }

    .sidebar-header-mobile{
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin: .5rem 0;
        padding: .5rem 0;
        cursor: pointer;
    }
    .sidebar-header{
        display: flex;
        text-align: left;
        margin-bottom: 2rem;
    }

    .iconUser{
        color: #D30C53;
        font-size: 2rem;
        margin-right: .8rem;
        align-self: flex-end;
    }

    .user-mobile{
        color: #c90c53;
        font-weight: 700;
        text-transform: uppercase;
    }
    .user{
        align-self: center;
        color: #000;
        font-size: .875rem;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1rem;
    }

    .options-container-mobile{
        list-style: none;
        padding: 0;
        width: 100vw;
        max-width: 100vw;
        margin-bottom: 0;
        position: absolute;
        left: -5%;
        top: 9rem;
        z-index: 10;
        background-image: linear-gradient(to bottom, transparent 0%, #fff 7%);
        box-shadow: 0px 15px 15px -8px rgb(99, 99, 99);
    }
    .options-container{
        list-style: none;
        padding: 0;
        margin-top: 3rem;
        margin-bottom: 0;
    }

    .option-container-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .option-container-mobile:hover .option-mobile{
        color: #c90c53;
    }
    .option-container{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .option-container:hover .option{
        color: #c90c53;
    }

    .iconArrowRight{
        color: #ff50c2;
    }

    .option-mobile{
        color: #b7b7b7;
        font-size: .875rem;
        font-weight: 400;
        line-height: 2.375rem;
        text-transform: uppercase;
        margin: 0;
    }
    .option{
        color: #b7b7b7;
        font-size: .875rem;
        font-weight: 400;
        line-height: 2.375rem;
        text-transform: uppercase;
        margin: 0;
        margin-left: 1.24rem;
    }

    .active{
        color: #c90c53;
    }

    .icon{
        color: #FF6EA9;
        font-size: 2rem;
        cursor: pointer;
    }
</style>