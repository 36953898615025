<template>
    <div :class="`${isMobile ? 'containerAll-mobile' : 'containerAll'}`">
        <div :class="`${isMobile ? 'content-mobile col-11 mx-auto' : 'content col-10 mx-auto'}`">
            <h1 :class="`${isMobile ? 'title-mobile' : 'title'}`">
                Veja como é simples adquirir seu plano!
                <span v-if="!isMobile" class="material-icons-outlined icon">trending_flat</span>
            </h1>
            <div :class="`${isMobile ? 'item-mobile' : 'item'}`">
                <p class="number">01</p>
                <div :class="`${isMobile ? 'itemsContainer-mobile' : 'itemsContainer'}`">
                    <h2 :class="`${isMobile ? 'subtitle-mobile' : 'subtitle'}`">Informe sua Localização</h2>
                    <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                        Selecione o local desejado e encontre os melhores planos disponíveis para sua região.
                    </p>
                </div>
            </div>
            <div :class="`${isMobile ? 'item-mobile' : 'item'}`">
                <p class="number">02</p>
                <div :class="`${isMobile ? 'itemsContainer-mobile' : 'itemsContainer'}`">
                    <h2 :class="`${isMobile ? 'subtitle-mobile' : 'subtitle'}`">Consulte os Planos</h2>
                    <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                        Consulte opções, serviços e veja qual opção mais se encaixa com você.
                    </p>
                </div>
            </div>
            <div :class="`${isMobile ? 'item-mobile' : 'item'}`">
                <p class="number">03</p>
                <div :class="`${isMobile ? 'itemsContainer-mobile' : 'itemsContainer'}`">
                    <h2 :class="`${isMobile ? 'subtitle-mobile' : 'subtitle'}`">Contrate seu Plano</h2>
                    <p :class="`${isMobile ? 'text-mobile' : 'text'}`">
                        Faça seu cadastro de maneira rápida e segura, sem sair de casa.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name: "StepByStep",
    props: {
        isMobile: Boolean
    }
}
</script>

<style scoped>
    .containerAll-mobile{
        background-color: #fff;
        padding: 5.9375rem 0;
    }
    .containerAll{
        background-color: #a70f49;
        padding: 5.9375rem 0;
        color: #fff;
    }

    .content-mobile{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0;
    }
    .content{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 0;
    }

    .item-mobile{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 98%;
        text-align: left;
        margin-left: 0;
        margin-bottom: .8rem;
    }
    .item{
        text-align: left;
        width: 20.5%;
        margin-left: 1.5rem;
    }

    .title-mobile{
        color: #c90c53;
        text-align: left;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
        margin-bottom: 2.0625rem;
    }
    .title{
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -.64px;
        width: 20rem;
        text-align: left;
        position: relative;
    }

    .icon{
        color: #ff96db;
        font-size: 3rem;
        position: absolute;
        bottom: -.4rem;
        right: 3rem;
    }

    .number{
        color: #ff96db;
        font-size: 2.1875rem;
        font-weight: 400;
        margin: 0;
    }

    .subtitle-mobile{
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        margin-top: .5rem;
        margin-bottom: .625rem;
    }
    .subtitle{
        color: #00fff5;
        font-size: 1rem;
        font-weight: 700;
        margin-top: 1.125rem;
        margin-bottom: .625rem;
    }

    .text-mobile{
        color: #747474;
        font-size: .8125rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .text{
        font-size: .8125rem;
        font-weight: 400;
        line-height: 1.25rem;
    }

    .itemsContainer-mobile{
        width: 75%;
    }
</style>
