<template>
    <template v-if="type === 'default'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            readonly
            disabled
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            @blur="blur"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'textarea'">
        <textarea
            v-if="readonly"
            :class="className"
            :placeholder="placeholder"
            :value="modelValue"
            @change="(event) => $emit('update:modelValue', (event.target.value))"
            readonly
            disabled
            :autocomplete="autocomplete"
        >
        </textarea>
        <textarea
            v-else
            :class="className"
            :rows="rows"
            :cols="cols"
            :placeholder="placeholder"
            :value="modelValue"
            @change="(event) => $emit('update:modelValue', (event.target.value))"
            :autocomplete="autocomplete"
        >
        </textarea>
    </template>

    <template v-if="type == 'tel'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            @blur="blur"
            :value="modelValue"
            v-maska="['## (##) ####-####', '## (##) #####-####', '(##) ####-####', '(##) #####-####']"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'date'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            :max="maxDate"
            :min="minDate"
            @input="(event) => $emit('update:modelValue', event.target.value)"
        />
    </template>

    <template v-if="type == 'cep'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            :value="modelValue"
            v-maska="'#####-###'"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            @blur="blur"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'number'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            type="number"
            :placeholder="placeholder"
            :value="modelValue"
            :min="min"
            :max="max"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'cardNumber'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'#### #### #### ####'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'rg'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'######################'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'expDate'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'##/##'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'birth'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'##/##/####'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'cvv'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'###'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'cpf'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'###.###.###-##'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'cns'">
        <input
            v-if="readonly"
            :class="className"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            inputmode="numeric"
            :placeholder="placeholder"
            v-maska="'##########################'"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'password'">
        <input
            v-if="readonly"
            :class="className"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            readonly
            disabled
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
        <input
            v-else
            :class="className"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            :autocomplete="autocomplete"
        />
    </template>

    <template v-if="type == 'select'">
        <select
            :value="modelValue"
            @change="(event) => $emit('update:modelValue', event.target.value)"
            @blur="blur"
            @input="input"
            :class="className"
        >
            <option hidden value="" selected disabled>{{ placeholder }}</option>
            <template v-for="item in data" :key="item.label">
                <option :value="item.value">{{ item.label }}</option>
            </template>
        </select>
    </template>

    <template v-if="type == 'select-parentesco'">
        <select
            :value="modelValue"
            @change="(event) => $emit('update:modelValue', event.target.value)"
            @blur="blur"
            @input="input"
            :class="className"
        >
            <option hidden value="" selected disabled>{{ placeholder }}</option>
            <option value="Nenhum, sou responsável financeiro">Nenhum, sou responsável financeiro</option>
              <option value="Cônjuge">Cônjuge</option>
              <option value="Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial">Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial</option>
              <option value="Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino">Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino</option>
              <option value="Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos">Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos</option>
              <option value="Neto(a)/bisneto(a)">Neto(a)/bisneto(a)</option>
              <option value="Irmão(ã)">Irmão(ã)</option>
              <option value="Pais, avós e bisavós">Pais, avós e bisavós</option>
              <option value="Tio(a)">Tio(a)</option>
              <option value="Sogro(a)">Sogro(a)</option>
              <option value="Genro/nora">Genro/nora</option>
              <option value="Cunhado(a)">Cunhado(a)</option>
              <option value="Padrasto/madrasta">Padrasto/madrasta</option>
              <option value="Sobrinho(a)">Sobrinho(a)</option>
        </select>
    </template>
</template>

<script>
import { computed } from 'vue';

export default{
    name: "Input",
    props: {
        type: String || null,
        className: String || null,
        placeholder: String || null,
        modelValue: String || Array,
        readonly: Boolean || null,
        autocomplete: String || null,
        data: Array || null,
        rows: Number || null,
        cols: Number || null,
        min: Number || null,
        max: Number || null,
        blur: Function || null,
        input: Function || null
    },
    setup(props, { emit }) {
        computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        return { props };
    },
}
</script>

<style scoped>
    .input-contato{
        width: 100%;
        height: 3.125rem;
        border-radius: .25rem;
        border: 1px solid #c2ccd9;
        background-color: #fff;
        padding-left: 1.375rem;
        outline: none;
        color: #f5267a;
        margin: .5rem 0 .5rem 0;
        font-size: .92rem;
    }
    .textarea-contato{
        width: 100%;
        border-radius: .25rem;
        border: 1px solid #c2ccd9;
        background-color: #fff;
        padding-left: 1.375rem;
        outline: none;
        color: #f5267a;
        margin: .5rem 0 .5rem 0;
        font-size: .92rem;
    }

    .input-contato::placeholder, .textarea-contato::placeholder{
        color: #f5267a;
    }

    .input-login{
        margin-bottom: 1.75rem;
        background-color: #e8f0fe;
        border: 1px solid #ff96db;
        color: #000;
        font-size: .85rem;
        font-weight: 500;
        line-height: .9375rem;
        border-radius: 5px;
        height: 2.625rem;
        padding-left: 1rem;
        width: 100%;
    }
    .input-login:focus{
        outline: none;
    }

    .input-sidebar-minhaArea{
        height: 2.625rem;
        border: 1px solid #cecece;
        width: 100%;
        border-radius: .3125rem;
        padding-left: .9375rem;
        font-size: .75rem;
        font-weight: 700;
        color: #000;
        outline: none;
    }
    .input-sidebar-minhaArea:hover{
        outline: none;
        border: 1px solid #ff96db;
    }
    .input-sidebar-minhaArea:focus{
        outline: none;
        border: 1px solid #ff96db;
    }
    .input-sidebar-minhaArea::placeholder{
        text-transform: initial;
    }

    option{
        color: #7e7e7e;
        font-size: .875rem;
        font-weight: 400;
        line-height: .9375rem;
        cursor: pointer;
    }

    .error{
        border: solid 2px #ff96db !important;
    }

    .uppercase{
        text-transform: uppercase;
    }

    .success-class {
        border: solid 2px #34a007;
    }

    .error-class {
        border: solid 2px #c90c0c;
    }
</style>
