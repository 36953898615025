import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import MinhaArea from '../views/MinhaArea.vue';
import Detalhes from '../views/DetalhesPlano.vue';
import Sobre from '../views/Sobre.vue';
import Planos from '../views/Planos.vue';
import Pagamento from '../views/Pagamento.vue';
// import Noticia from './views/noticia'
import Duvidas from '../views/Duvidas.vue';
// import DetalheNoticia from './views/detalhe_noticia'
import Dependentes from '../views/Dependentes.vue';
import Contato from '../views/Contato.vue';
import Finalizado from '../views/Finalizado.vue';
// import Opcoes from './views/opcoes_pagamento'
import NotFound from '../views/NotFound.vue';
import Cadastro from '../views/Cadastro.vue';
import VerificaEmail from '../views/VerificaEmail.vue';
import ConfirmaEmail from '../views/ConfirmaEmail.vue';
import MeusDados from '../views/MeusDados.vue';


const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/login', name: 'Login', redirect: '/' },
  { path: '/minha-area', name: 'Area', component: MinhaArea },
  { path: '/sobre', name: 'Sobre', component: Sobre },
  { path: '/planos/:estado/:cidade', name: 'Planos', component: Planos },
  { path: '/finalizar-compra/pagamento', name: 'Pagamento', component: Pagamento },
  { path: '/uniodonto/:id_estado/:id_cidade/detalhes/planos/:id', name: 'Detalhes', component: Detalhes},
  // { path: '/noticia', name: 'Noticia', component: Noticia },
  { path: '/duvidas', name: 'Duvidas', component: Duvidas },
  // { path: '/detalhe-noticia/:id', name: 'DetalheNoticia', component: DetalheNoticia },
  { path: '/finalizar-compra/dependentes', name: 'Dependentes', component: Dependentes },
  { path: '/contato', name: 'Contato', component: Contato },
  // { path: '/finalizar-compra/opcoes-pagamento', name: 'Opcoes', component: Opcoes },
  { path: '/finalizar-compra/finalizado/:tipo/:protocolo', name: 'Finalizado', component: Finalizado },
  { path: '/finalizar-compra/cadastro', name: 'Cadastro', component: Cadastro},
  { path: '/verifica-email', name: 'VerificaEmail', component: VerificaEmail},
  { path: '/confirmar_email/:token', name: 'ConfirmaEmail', component: ConfirmaEmail},  
  { path: '/minha-area/meusdados', name: 'MeusDados', component: MeusDados},  
  { path: '/404', name: 'NotFound', component: NotFound},
  { path: "/:catchAll(.*)", redirect: '/404' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router