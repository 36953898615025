<template>
    <template v-if="store.getters.modalTitular.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-container">
            <div class="modalTitular-header">
                <h3 class="title">Complete os dados do tutor</h3>
                <span class="material-icons-outlined iconClose" @click="closeModal">close</span>
            </div>
            <p class="text">Verificamos que os dependentes são menores de idade. Com isso precisamos de mais algumas informações do responsável. Complete-as abaixo:</p>
            <form class="formContainer">
                <label class="container-meus-dados">
                    <input
                        @change="(event) => getUserInfo(event.target.checked)"
                        type="checkbox"
                        v-model="store.getters.myDataAlreadyCheckedModalTitular"
                    />
                    Usar meus dados de cadastro, pois sou o tutor legal
                </label>
                <div class="inputContainer">
                    <label>Nome do tutor</label>
                    <Input
                        type="default"
                        :className="store.getters.errors.titular[0] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Nome"
                        v-model="store.getters.titular.name"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[0]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>CPF do tutor</label>
                    <Input
                        type="cpf"
                        :className="store.getters.errors.titular[1] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="CPF"
                        autocomplete="off"
                        v-model="store.getters.titular.cpf"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[1]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Email do tutor</label>
                    <Input
                        type="default"
                        :className="store.getters.errors.titular[2] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Email"
                        v-model="store.getters.titular.email"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[2]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Data de Nascimento do tutor</label>
                    <Input
                        type="birth"
                        :className="store.getters.errors.titular[3] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="DD/MM/AAAA"
                        v-model="store.getters.titular.birth_date"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[3]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>RG do tutor</label>
                    <Input
                        type="rg"
                        :className="store.getters.errors.titular[4] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="RG"
                        v-model="store.getters.titular.rg"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[4]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Nome da Mãe do tutor</label>
                    <Input
                        type="default"
                        :className="store.getters.errors.titular[5] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Nome da Mãe"
                        v-model="store.getters.titular.nome_mae"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[5]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Parentesco do tutor</label>
                    <Input
                        type="select"
                        :className="store.getters.errors.titular[6] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Selecione o grau de parentesco"
                        v-model="store.getters.titular.parentesco"
                        :data="[
                            {label: 'Pai', value: 'Pai'},
                            {label: 'Mãe', value: 'Mae'},
                            {label: 'Nenhum, sou tutelar do(s) dependente(s)', value: 'Nenhum, sou tutelar do(s) dependente(s)'},
                        ]"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[6]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Estado Civil</label>
                    <Input
                        type="select"
                        :className="store.getters.errors.titular[7] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Selecione o estado civil"
                        v-model="store.getters.titular.estado_civil"
                        :data="[
                            {label: 'Amasiado', value: 'Amasiado'},
                            {label: 'Casado', value: 'Casado'},
                            {label: 'Convivente', value: 'Convivente'},
                            {label: 'Desquitado', value: 'Desquitado'},
                            {label: 'Divorciado', value: 'Divorciado'},
                            {label: 'Separado', value: 'Separado'},
                            {label: 'Solteiro', value: 'Solteiro'},
                            {label: 'Viúvo', value: 'Viúvo'},
                            {label: 'Outros', value: 'Outros'},
                        ]"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[7]">
                        {{ textError }}
                    </span>
                </div>
                <div class="inputContainer">
                    <label>Gênero</label>
                    <Input
                        type="select"
                        :className="store.getters.errors.titular[8] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                        placeholder="Selecione o gênero"
                        v-model="store.getters.titular.genero"
                        :data="[
                            {label: 'Feminino', value: 'Feminino'},
                            {label: 'Masculino', value: 'Masculino'},
                            {label: 'Outros', value: 'Outros'},
                        ]"
                    />
                    <span class="msgErro" v-if="store.getters.errors.titular[8]">
                        {{ textError }}
                    </span>
                </div>
                <Button type="cadastro" text="Efetivar cadastro" :click="validateHolder" />
            </form>
        </div>
    </template>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator';
import { useStore } from 'vuex';
import { isBirthday, isEmail, isEmpty, isName } from '../../../services/validate';
import Button from '../../Elements/Button.vue';
import Input from '../../Elements/Input.vue';
import { inject, ref } from 'vue';
import { is18orMoreYearsOld, registerDependentsWithHolder } from '../../../services/util';
import { numberToCpf, toDate, toTimeStamp } from '../../../services/formater';

export default {
    name: "ModalTitular",
    components:{
        Button,
        Input
    },
    created() {
        this.isMobile = window.innerWidth < 500 ? true : false;
        this.textError = this.isMobile 
                ? "*Verifique se os dados são válidos!"
                : "*Verifique se o campo está preechido com dados válidos!"
        window.addEventListener("resize", () => {
            this.isMobile = window.innerWidth < 500 ? true : false;
            this.textError = this.isMobile 
                ? "*Verifique se os dados são válidos!"
                : "*Verifique se o campo está preechido com dados válidos!"
        });
    },
    setup() {
        const store = useStore();
        const alert = inject("alert")
        const loader = inject("loading");
        const isMobile = ref(false);
        const textError = ref("")
        
        const closeModal = () => {
            let dependentes = store.getters.dependentes;
            dependentes.map( dependente => {
                dependente.cpf = numberToCpf(dependente.cpf);
                // dependente.cns = dependente.cns.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
            });
            store.commit("setDependentes", dependentes);
            store.commit("setModalTitular", {display: false});
        };

        const getUserInfo = (value) => {
            store.commit("setMyDataAlreadyCheckedModalTitular", value);
            const titular = {
                name: "",
                cpf: "",
                email: "",
            };
            if (value) {
                titular.name = `${store.getters.subscriber.name} ${store.getters.subscriber.last_name}`;
                titular.email = store.getters.subscriber.email;
                titular.cpf = numberToCpf(store.getters.subscriber.cpf);
            }
            store.commit("updateTitular", titular);
        };

        const validateHolder = () => {
            loader.open();
            const titular = store.getters.titular;
            const emailValidate = isEmail(titular.email);
            const cpfValidate = cpf.isValid(titular.cpf);
            const birthValidate = isBirthday(titular.birth_date);
            const nameValidate = isName(titular.name);
            const nome_maeValidate = isName(titular.nome_mae);
            const validate = isEmpty(titular);

            if (validate && emailValidate && cpfValidate && birthValidate && nameValidate && nome_maeValidate) {
                titular.cpf = titular.cpf.replace(/\D/g, "");
                let year = parseInt(titular.birth_date.split("/")[2]);
                let month = parseInt(titular.birth_date.split("/")[1]);
                let day = parseInt(titular.birth_date.split("/")[0]);
                titular.birth_date = toTimeStamp(titular.birth_date);
                if (is18orMoreYearsOld(day, month, year)) {
                    registerDependentsWithHolder(true);
                    store.commit("setModalTitular", {display: false});
                } else {
                    alert.open("Atenção!", "O responsável deve ser maior de idade!");
                    titular.birth_date = toDate(titular.birth_date);
                    titular.cpf = numberToCpf(titular.cpf);
                }
            } else {
                if(!validate) alert.open("Atenção!", "Verifique se todos os campos do responsável foram preechidos corretamente.");
                else if(!nameValidate) alert.open("Atenção!", `Nome não deve conter caracteres especiais ou números!`);
                else if(!nome_maeValidate) alert.open("Atenção!", `Nome da mãe não deve conter caracteres especiais ou números!`);
                else if(!cpfValidate) alert.open("Atenção!", "CPF do responsável inválido!");
                else if(!emailValidate) alert.open("Atenção!", "Email do responsável inválido!");
                else if(!birthValidate) alert.open("Atenção!", "Data de aniversário do responsável inválida!");
            }
            Object.keys(titular).map( (prop, index) => {
                (titular[prop] == "" || titular[prop] == undefined)
                    ? store.commit("setErrorsTitular", {index, value: true})
                    : store.commit("setErrorsTitular", {index, value: false})
            });
            loader.close();
        };

        return { closeModal, store, validateHolder, getUserInfo, isMobile, textError }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .75);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 60vw;
        max-height: 90vh;
        margin: auto;
        z-index: 9999999;
        text-align: justify;
        padding: 2rem;
        border-radius: 10px;
    }

    .modalTitular-header{
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 1rem;
    }

    .formContainer{
        height: 23rem;
        overflow-y: auto;
        padding-right: 1rem;
    }

    .title{
        color: #c90c53;
        font-size: 1.1875rem;
        font-weight: 600;
        position: relative;
        margin-bottom: 0;
        width: 70%;
    }

    .text{
        color: #747474;
        font-size: .875rem;
        font-weight: 400;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 1rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .iconClose{
        color: #c90c53;
        user-select: none;
        cursor: pointer;
    }

    .msgErro {
        color: #c90c53;
        font-size: .625rem;
        font-weight: bold;
        position: absolute;
        bottom: -15px;
        left: 0;
    }

    .container-meus-dados{
        display: flex;
        align-items: center;
        width: 24.5rem;
        height: 2.4375rem;
        border-radius: .25rem;
        background-color: rgba(237, 235, 235, 0.6);
        padding: .9375rem;
        margin-bottom: 2.1875rem;
        color: #c90c53;
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: -0.24px;
    }

    .container-meus-dados input{
        margin-right: 1rem;
    }

    @media screen and (max-width: 800px) {

        .modal-container{
            width: 100%;
            height: 100vh;
            max-height: none;
            background-color: #fff;
            margin: auto;
            z-index: 9999999 !important;
            text-align: justify;
            padding: 2rem;
            border-radius: 0;
        }

        .container-meus-dados{
            width: auto;
        }
    }


</style>
