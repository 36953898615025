import store from '../../store/index'

export default {
    open(title, message){
        store.commit('alert', {
            display: true,
            title: title,
            message: message,
        });
    },
    close(){
        store.commit('alert', {
            display: false,
        });
    }
}