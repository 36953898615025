import axios from "axios";
import router from "../router";
import { createEventosSite } from "./util";
import store from '../../store/index';

const isProd = process.env.NODE_ENV === 'production';

const instance = axios.create({
  baseURL: (isProd) ? 'https://api.comprodonto.com.br/api/' : 'http://127.0.0.1:8000/api',
  headers: {
    Authorization: localStorage.getItem("token_comprodonto")
      ? `Bearer ${localStorage.getItem("token_comprodonto")}`
      : "",
  },
});

instance.interceptors.request.use((config) => {
  if (!config.url.includes('viacep.com.br')) {
    let token = JSON.parse(localStorage.getItem("token_comprodonto"));
    config.headers.Authorization = (token) ? `Bearer ${token}` : '';
  } else {
    delete config.headers.Authorization;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const GET = (url) =>
  instance
    .get(url || "")
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data;
    });

const POST = (url, params) =>
  instance
    .post(url || "", params)
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data;
    });

const PUT = (url, params) =>
  instance
    .put(url || "", params)
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data;
    });

const DELETE = (url) =>
  instance
    .delete(url || "")
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data;
    });

const LOGIN = (route, payload) =>
  instance
    .post(route, payload)
    .then((res) => res.data)
    .then((res) => {
      return res.token;
    })
    .catch((e) => {
      throw e.response.data;
    });

  router.afterEach(async () => {
    if (router.currentRoute.value.redirectedFrom && router.currentRoute.value.redirectedFrom.path === '/login') {
      store.commit("setModalLogin", {display: true});
      createEventosSite('VISUALIZACAO', 'modal_login'); 
    }

    let queryParams = "";
    if(Object.entries(router.currentRoute.value.query).length > 0){
      queryParams += "?";
      let qtdParams = 0;
      for (var [key, value] of Object.entries(router.currentRoute.value.query)) {
        queryParams+= key + '=' + value;
        if(Object.entries(router.currentRoute.value.query)[qtdParams+1])
          queryParams+="&"
        qtdParams++;
      }
    }

    createEventosSite('VISUALIZACAO', router.currentRoute.value.path+queryParams);
  })

export { GET, POST, PUT, DELETE, LOGIN, instance };
