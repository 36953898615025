<template>
    <Navbar type="compra" :background="true" :isMobile="isMobile" :path="path" />
    <div :class="`${isMobile ? 'content-container-mobile col-11 mx-auto' : 'content-container col-10 mx-auto'}`">
        <div :class="`${isMobile ? 'coluna-1-mobile' : 'coluna-1'}`">
            <FormCadastro :plans="plans" />
            <FormAddress :abrangencia="abrangencia" />
            <div class="doc-container">
                <h3 class="subtitle">Documentação e contrato</h3>
                <ul class="list">
                    <li v-for="arquivo in arquivos" :key="arquivo.id" class="item-list">
                        <a :href="arquivo.path" target="_blank">{{ arquivo.nome }}</a>
                    </li>
                </ul>
            </div>
            <Button type="cadastro" text="Cadastrar" :click="register" />
        </div>

        <ShoppingCarDetails :shoppingCar="store.getters.shoppingCar" :isMobile="isMobile" />
    </div>
    <Footer type="compra" :isMobile="isMobile" />
</template>

<script>
import Navbar from "../components/Elements/Navbar.vue";
import FormCadastro from "../components/FinalizarCompra/Cadastro/FormCadastro.vue";
import FormAddress from "../components/FinalizarCompra/Cadastro/FormAddress.vue";
import Footer from "../components/Elements/Footer.vue";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import Button from '../components/Elements/Button.vue';
import ShoppingCarDetails from '../components/FinalizarCompra/Cadastro/ShoppingCarDetails.vue';
import { useStore } from 'vuex';
import { POST } from '../services/api';
import { cpf } from "cpf-cnpj-validator";
import { isEmpty, isEmail, isName, isBirthday } from "../services/validate";
import { numberToCpf } from '../services/formater';
import { createEventosSite, setErrorsFormCadastro, getCookie, updateShoppingCar, is18orMoreYearsOld, validatePhone } from '../services/util';

export default {
    name: "Cadastro",
    components: {
        Navbar,
        FormCadastro,
        FormAddress,
        ShoppingCarDetails,
        Footer,
        Button,
    },
    created() {
        this.isMobile = window.innerWidth < 800 ? true : false;
        window.addEventListener("resize", () => {
            this.isMobile = window.innerWidth < 800 ? true : false;
        });
    },
    setup() {
        const loader = inject("loading");
        const alert = inject("alert");
        const modal = inject("modal");
        const shoppingCar = inject("shoppingCar");
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const isMobile = ref(false);
        const path = route.path.split('/')[2];
        const arquivos = ref([]);
        const abrangencia = ref([]);
        const plans = ref([]);
        const id_lead = ref("");
        const set = (newValue)=> {
                const [day, month, year] = newValue.split('/');
                let formatDate = `${year}-${month}-${day}`;
                console.log('forddate',formatDate)
                return formatDate
            }
        const cityValidate = (user)=>{
            if(user.city==undefined){
                return false
            } else{
                return true
            }
        }

        const register = async () => {
            loader.open();
            const user = store.getters.user;
            const user_cadastro = store.getters.user_cadastro;
            if(user_cadastro.telefone.length <= 15) {
                user.phones.number = user_cadastro.telefone.split(")")[1].replace(/\D/g, "");
                user.phones.area_code = user_cadastro.telefone.split(")")[0].replace(/\D/g, "");
            } else if(user_cadastro.telefone.length > 15) {
                user.phones.number = user_cadastro.telefone.split(" ")[2].replace(/\D/g, "");
                user.phones.area_code = user_cadastro.telefone.split(" ")[1].replace(/\D/g, "");
                user.phones.country_code = user_cadastro.telefone.split(" ")[0].replace(/\D/g, "");
            } else {
                user.phones.number = "";
                user.phones.area_code = "";
            }

            const lastNameValidate = user.name.includes(" ");
            const validate = isEmpty(user);
            const cpfValidate = cpf.isValid(user.cpf);
            const emailValidate = isEmail(user.email);
            const nameValidate = isName(user.name);
            const birthValidate = isBirthday(user.birth_date);
            const passwordValidate = user.password === user_cadastro.confirmaPassword ? true : false;
            let year = parseInt(user.birth_date.split("/")[2]);
            let month = parseInt(user.birth_date.split("/")[1]);
            let day = parseInt(user.birth_date.split("/")[0]);
            const is18orMore = is18orMoreYearsOld(day, month, year);
            const phonevalidate = validatePhone(user.phones.number)
            const cityvalidate=cityValidate(user)
            if (validate && cpfValidate && emailValidate && user_cadastro.terms && passwordValidate && nameValidate && lastNameValidate && birthValidate && is18orMore&&phonevalidate&&cityvalidate) {
                user.name = user.name.trim();
                user.last_name = user.name.split(" ").splice(1).toString().replaceAll(",", " ").trim();
                user.name = user.name.split(" ")[0].trim();
                user.login = user.email;
                user.zip_code = user.zip_code.replace(/\D/g, "");
                user.cpf = user.cpf.replace(/\D/g, "");
                user.plans = plans.value;
                user.naturalidade = user.city;
                user.birth_date = set(user.birth_date)
                id_lead.value = getCookie('lead_id_cookie');
                user.lead_id = id_lead.value;
                try {
                    const response = await POST("registro", user);
                    localStorage.setItem("token_comprodonto", '"' + response.token + '"');
                    delete user.password;
                    localStorage.setItem("user", JSON.stringify(user));
                    store.commit("setUser", user);
                    localStorage.setItem("email_notVerified_comprodonto", JSON.stringify(user.email));
                    store.commit("setTextSidebar", 'Minha área');
                    router.replace("/verifica-email");
                } catch (e) {
                    if (e.message.includes("já está em uso")) {
                        modal.open(
                            "Atenção!",
                            `${e.message}<br><br>Deseja fazer login neste email agora?`,
                            'Confirmar',
                            'Cancelar',
                            () => {
                                store.commit("setModalLogin", {display: true});
                                createEventosSite('VISUALIZACAO', 'modal_login');
                            },
                            () => modal.close()
                        )
                    } else {
                        alert.open('Erro!', e.message)
                    }
                    user.zip_code = `${user.zip_code.substring(0, 5)}-${user.zip_code.substring(5)}`;
                    user.cpf = numberToCpf(user.cpf);
                    user.name = `${user.name} ${user.last_name}`;
                    store.commit("setUser", user);
                    setErrorsFormCadastro(user, user_cadastro);
                    window.scrollTo(0, 0);
                    loader.close();
                }
            } else {
                if(!lastNameValidate) alert.open("Atenção!", "Por favor, preencha o nome completo.");
                else if(!validate) alert.open("Atenção!", "Verifique se todos os campos foram preechidos corretamente.");
                else if(!nameValidate) alert.open("Atenção!", 'Nome não deve conter caracteres especiais ou números!');
                else if(!passwordValidate) alert.open("Atenção!", 'Os valores dos campos "Senha" e "Confirme sua senha" não conferem.');
                else if(!cpfValidate) alert.open("Atenção!", "CPF inválido.");
                else if(!emailValidate) alert.open("Atenção!", "Email inválido.");
                else if(!user_cadastro.terms) alert.open("Erro!", "Concorde com os termos de uso para continuar.");
                else if(!birthValidate) alert.open("Atenção!", "Data de aniversário inválida!");
                else if(!is18orMore) alert.open("Atenção!", "O Responsável pelo contrato precisa ter 18 anos ou mais!");
                else if (!phonevalidate) alert.open("Atenção!", "Número de telefone inválido.");
                else if (!cityvalidate) alert.open("Atenção!", "Cidade não é válida");
                setErrorsFormCadastro(user, user_cadastro);
                window.scrollTo(0, 0);
            }
            loader.close();
        };

        onMounted( async () => {
            loader.open();
            store.getters.shoppingCar.plans.map(plan => plans.value.push({ id: plan.id, qtd: plan.qtd }));

            try{
                const response = await POST("verificaandamentov3", { plans: plans.value });
                arquivos.value = response.arquivos;
                abrangencia.value = response.abrangencia;
                await updateShoppingCar();
                store.getters.shoppingCar.plans.map(plan => plans.value.push({ id: plan.id, qtd: plan.qtd }));

                if (response.rota != "cadastro" && response.rota != "/minha-area")
                    router.push("/finalizar-compra/" + response.rota);
                else if(response.rota === "/minha-area")
                    router.push("/minha-area");

            } catch (err) {
                shoppingCar.finish();
                if (err.rota == "/home") {
                    alert.open(
                        "Abrangência Restrita",
                        "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
                    );
                    router.replace("/");
                } else if (err.rota == "/verifica-email") {
                    alert.open(
                        "Usuário não autenticado!",
                        "É necessário confirmar o email para prosseguir!"
                    );
                    router.replace('/verifica-email');
                } else {
                    alert.open(
                        "Erro ao verificar cadastro",
                        "Não foi possível identificar o motivo do erro. Tente novamente por favor"
                    );
                    router.replace("/");
                }
            }
            loader.close();
        });

        return { isMobile, path, arquivos, store, abrangencia, plans, register };
    },
}
</script>

<style scoped>
    .content-container-mobile{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 150px 0 6rem 0;
    }
    .content-container{
        display: flex;
        justify-content: space-between;
        padding: 94px 0 6rem 0;
    }

    .coluna-1-mobile{
        margin-right: 0;
    }
    .coluna-1{
        margin-right: 2.2rem;
    }

    .doc-container{
        max-width: 18.4375rem;
        margin-top: 1.75rem;
        height: auto;
        background-color: #ffffff;
        text-align: left;
    }

    .list{
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        margin-top: .8rem;
        margin-left: 1.2rem;
    }

    .item-list{
        color: #747474;
        text-align: left;
        margin: 1rem 0;
        line-height: 1rem;
    }
    .item-list::marker{
        color: #000;
    }

    .item-list a{
        text-decoration: none;
        color: #747474;
    }

    .subtitle{
        color: #000000;
        font-size: 1.0625rem;
        font-weight: 600;
        margin-bottom: .9375rem;
        text-transform: uppercase;
    }
</style>