<template>
    <Navbar type="default" :background="true" :isMobile="isMobile" />
    <div 
        v-if="Object.keys(detailsData).length > 0"
        :class="`${isMobile ? 'containerAll-mobile col-11 mx-auto' : 'containerAll col-10 mx-auto'}`"
    >
        <!-- <SideBarPerfil 
            v-if="!isMobile || (!store.getters.hasDetalhesPlano && isMobile)" 
            :user="detailsData.usuario" 
            :isMobile="isMobile" 
            :boleto="isBoleto"
        /> -->
        <MeusDados
            :detalhesData="detailsData" 
            :isMobile="isMobile" 
        />
    </div>
    <div v-else class="height"></div>
    <Footer type="default" :isMobile="isMobile" />
</template>

<script>
import { inject, ref } from 'vue';
import Navbar from '../components/Elements/Navbar.vue';
//import SideBarPerfil from '../components/MinhaArea/SidebarPerfil.vue';
import Footer from '../components/Elements/Footer.vue';
//import MeusPlanos from '../components/MinhaArea/MeusPlanos/MeusPlanos.vue';
import MeusDados from '../components/MinhaArea/MeusDados/MeusDados.vue';
//import MinhasFaturas from '../components/MinhaArea/MinhasFaturas/MinhasFaturas.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { createEventosSite, decodeJwt } from '../services/util';
import { GET } from '../services/api';

export default {
    name: "MinhaArea",
    components:{
        Navbar,
        //SideBarPerfil,
        //MeusPlanos,
        MeusDados,
       // MinhasFaturas,
        Footer
    },
    async created() {
        const store = useStore();
        const loader = inject("loading");
        const alert = inject("alert");
        const router = useRouter();
        loader.open();

        this.isMobile = window.innerWidth < 800 ?  true : false;
        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 800 ?  true : false;
        });

        const token = JSON.parse(localStorage.getItem("token_comprodonto"));
        if (token) {
            let decoded = decodeJwt(token);
            try{
                this.detailsData = await GET(`perfil/${decoded.sub}`);
                this.detailsData.usuario.verified_at === null && router.replace("/verifica-email");
                store.commit("setSectionMinhaArea", "planos");
            } catch (e) {
                alert.open("Erro", "Não foi possível recuperar seus dados")
                localStorage.clear();
                router.replace("/");
            }
        } else {
            localStorage.removeItem("token_comprodonto");
            alert.open("Atenção!", "Parece que você não tem permissão para acessar essa página!");
            router.replace("/");
            store.commit("setModalLogin", {display: true});
            createEventosSite('VISUALIZACAO', 'modal_login');
        }

        if (this.detailsData.subscriptions[0].boleto) this.isBoleto = true;

        loader.close();
    },
    setup() {
        const store = useStore();

        const isMobile = ref(false);
        const detailsData = ref({});
        const isBoleto = ref(false);

    
        return { isMobile, store, detailsData, isBoleto }
    }
}
</script>

<style scoped>
    .containerAll-mobile{
        padding: 0;
    }
    .containerAll{
        padding: 0;
        display: flex;
    }
    .height{
        height: 100vh;
        width: 100%;
    }
</style>