<template>
    <div :class="`${isMobile ? 'container-details-mobile col-11 mx-auto' : 'container-details col-10 mx-auto'}`">
        <ul v-if="!isMobile" class="list-menu">
            <li
                class="itemList-menu"
                v-for="item in itemsMenu"
                :key="item.id"
                @click="() => changeContent(item.id, item.id_eventosSite, false)"
            >
                <p :class="`${item.active ? 'text checked' : 'text'}`">
                  {{ item.menu }}
                </p>
            </li>
        </ul>
        <ul v-else class="list-menu-mobile">
            <li
                class="itemList-menu-mobile"
                v-for="item in itemsMenu"
                :key="item.id"
                @click="() => changeContent(item.id, item.id_eventosSite, true)"
            >
                <p class="text-mobile">
                  {{ item.menu }}
                  <span v-if="!item.active" class="material-icons-outlined iconList animate__animated animate__rotateIn">expand_more</span> 
                  <span v-else class="material-icons-outlined iconList animate__animated animate__rotateIn">expand_less</span> 
                </p>
            </li>
        </ul>

        <section v-if="currentSection !== ''" :class="`${isMobile ? 'content-container-mobile' : 'content-container'}`">
            <h3 :class="`${isMobile ? 'title-mobile' : 'title'}`">{{ itemsMenu[currentSection].title }}</h3>
            <div
                v-if="currentSection !== 2"
                :class="`${isMobile ? 'content-mobile' : 'content'}`"
                v-html="itemsMenu[currentSection].content"
            ></div>
            <ul v-else :class="`${isMobile ? 'content-mobile list-abrangencia' : 'content list-abrangencia'}`">
                <li
                    class="itemList-abrangencia"
                    v-for="abrangencia in itemsMenu[currentSection].content"
                    :key="abrangencia.id"
                >
                    <span class="material-icons iconCheck">check_circle</span>
                    {{ abrangencia.nome }}
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import { ref } from "vue";
import { createEventosSite } from '../../services/util';
export default {
  name: "Details",
  props: {
    isMobile: Boolean,
    plan: Object,
    abrangencia: Array,
  },
  created() {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 800 && this.currentSection === "") {
        this.itemsMenu.map(item => item.active = false);
        this.itemsMenu[0].active = true;
        this.currentSection = 0;
      }
    })
  },
  setup(props) {
    const itemsMenu = ref([
      {
        id: 0,
        menu: "Informações Gerais",
        title: "Informações Gerais",
        content: props.plan.descricao_vendas,
        active: props.isMobile ? false : true,
        id_eventosSite: 'botao_informacoes'
      },
      {
        id: 1,
        menu: "Cobertura",
        title: props.plan.name,
        content: props.plan.cobertura,
        active: false,
        id_eventosSite: 'botao_detalhe'
      },
      {
        id: 2,
        menu: "Área de abrangência",
        title: "Cidades",
        content: props.abrangencia,
        active: false,
        id_eventosSite: 'botao_area'
      },
      {
        id: 3,
        menu: "Dúvidas Frequentes",
        title: "Dúvidas Frequentes",
        content: props.plan.duvidas,
        active: false,
        id_eventosSite: 'botao_faq'
      },
    ]);
    const currentSection = ref(props.isMobile ? '' : 0);

    const changeContent = (id, id_eventosSite, mobile) => {
      createEventosSite("CLICK", id_eventosSite);
      if (mobile) {
        currentSection.value = currentSection.value === id ? '' : id;
        if (currentSection.value !== '') {
          itemsMenu.value.map((item) => item.active = item.id === id ? true : false);
          window.scrollTo(0, 870);
        } else {
          itemsMenu.value[id].active = false;
        }
      } else {
        currentSection.value = id;
        itemsMenu.value.map((item) => item.active = item.id === id ? true : false);
      }
    };

    return { currentSection, changeContent, itemsMenu };
  },
};
</script>

<style scoped>
.container-details-mobile {
  position: relative;
  padding: 0;
}
.container-details {
  position: relative;
  padding: 0;
}

.title-mobile{
  color: #343434;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.875rem;
  text-transform: uppercase;
  padding-top: 3rem;
}
.title{
  color: #343434;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.875rem;
  text-transform: uppercase;
  padding-top: 6rem;
}

.content-container-mobile{
  padding-bottom: 3rem;
}
.content-container{
  padding-bottom: 6rem;
}

.content-mobile {
  background-color: #fff;
  text-align: justify;
  padding: 0;
  height: auto;
  color: #343434;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.875rem;
  position: relative;
}
.content {
  background-color: #fff;
  text-align: left;
  padding: 0;
  overflow-y: auto;
  height: 25rem;
  color: #343434;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.875rem;
  position: relative;
  padding-left: 1.875rem;
}

.content p {
    margin: 0;
}

.list-menu-mobile {
  list-style: none;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  user-select: none;
}
.list-menu {
  list-style: none;
  position: absolute;
  top: -4.3125rem;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  user-select: none;
}

.itemList-menu-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f7f7;
  width: 100vw;
  position: relative;
  left: -4.5%;
  padding: 1.25rem 1rem;
  color: #000;
}
.itemList-menu {
  height: 4.3125rem;
  width: 11.4375rem;
  border-radius: 3px 3px 0 0;
  background-color: #910b3e;
  margin-right: 3px;
  transition: all 0.5s;
}

.text-mobile {
  opacity: 0.6;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 0;
}
.text {
  opacity: 0.6;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  border-radius: 4px 4px 0 0;
}

.iconList{
  position: absolute;
  right: 1rem;
  color: #c90c53;
  font-weight: 600;
}

.itemList-menu:hover .text {
  color: #c90c53;
  background-color: #fff;
  opacity: 1;
}

.checked {
  color: #c90c53;
  background-color: #fff;
  opacity: 1;
}

.list-abrangencia {
  list-style: none;
}

.itemList-abrangencia {
  display: flex;
  align-items: center;
}

.iconCheck {
  color: #c90c53;
  font-size: 1.25rem;
  user-select: none;
  margin-right: .6rem;
}
</style>
