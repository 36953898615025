<template>
  <div class="card h-100">
    <div class="container-img">
      <img :src="plan.foto_plano" alt="" />
    </div>
    <div class="container-text">
      <h3>
        {{ plan.name }}
      </h3>
      <p>{{ `${details.name} - ${details.end_estado}` }}</p>

      <div class="container-valor">
        <span class="material-icons iconCheck">check_circle</span> 
        <p>{{ toMoney(plan.price / 100) }}<span>mês</span></p>
      </div>
    </div>
    <div class="btn-planos">

      <a>
        Ver Detalhes
        <span class="material-icons-outlined iconArrowRight">chevron_right</span>
      </a>
      
    </div>
  </div>
</template>

<script>
import { toMoney } from "../../services/formater";

export default {
  name: "CardsPlanos",
  props: {
    plan: Object,
    details: Object,
  },
  setup() {
    return { toMoney };
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 0 1rem 4rem 1rem;
}

.container-img{
  width: 100%;
  height: 15.9375rem;
}
.container-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cards-planos .card .container-text {
  padding-top: 28px;
  padding-left: 37px;
  padding-right: 37px;
  margin-top:auto;
  padding-bottom: 25px;
  border: 1px solid #edebeb;
  border-top: none;
  cursor: pointer;
 
}
.cards-planos .card .container-text h3 {
  color: rgb(29, 29, 29);
  align-items: flex-start;
  min-height: 50px;
  margin-top:auto;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  display:flex;
  margin-bottom: 5px;
  cursor: pointer;
  animation: fadeIn 0.5s ease-in-out;
}
.cards-planos .card .container-text h3:hover {
  color: #c90c53;
}

.cards-planos .card .container-text p {
  color: #747474;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.cards-planos .card .container-text p:hover {
  color: #2a2a2a;
}

.cards-planos .card .btn-planos {
  height: 65px;
  border-radius: 0 0 4px 4px;
  background-color: #edebeb;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 37px;
  cursor: pointer;
}
.cards-planos .card .btn-planos:hover {
  background-color: #dadada;
}
.cards-planos .card .container-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
}
.cards-planos .card .container-img img:hover {
  opacity: 0.8;
}
.cards-planos .card .btn-planos a {
  color: #c90c53;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.cards-planos .card .btn-planos img {
  margin-left: 5px;
  cursor: pointer;
}

.cards-planos .card .container-text .container-valor {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.cards-planos .card .container-text .container-valor p {
  color: #c90c53;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: -0.14px;
  font-size: 20px;
  margin-bottom: 0;
  margin-left: 5px;
  cursor: pointer;
}
.cards-planos .card .container-text .container-valor p:hover {
  color: #cc3a72;
}
.cards-planos .card .container-text .container-valor p span {
  margin-left: 5px;
  cursor: pointer;
}
.cards-planos .card .container-text .container-valor span:hover {
  color: #cc3a72;
}

.iconCheck{
  color: #c90c53;
  font-size: 1.25rem;
  user-select: none;
}
.iconArrowRight{
  user-select: none;
}

@media screen and (min-width: 380px) {
  .card {
    width: 350px;
  }
}
</style>
