<template>
    <Navbar type="default" :background="true" :isMobile="isMobile" />
    <Doubtes :isMobile="isMobile"  />
    <Footer type="default" :isMobile="isMobile" />
</template>

<script>
import Navbar from '../components/Elements/Navbar.vue';
import Doubtes from '../components/Duvidas/Doubtes.vue';
import Footer from '../components/Elements/Footer.vue';
import { ref } from 'vue';

export default{
    name: "Duvidas",
    components:{
        Navbar,
        Doubtes,
        Footer
    },
    created() {
        this.isMobile = window.innerWidth < 800 ?  true : false;

        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 800 ?  true : false;
        })
    },
    setup() {
        const isMobile = ref(false);

        return { isMobile }
    }
}
</script>

<style scoped>

</style>
