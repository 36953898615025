<template>
    <div :class="`${isMobile ? 'container-meusplanos-mobile' : 'container-meusplanos'}`">
        <h1 v-if="!isMobile" class="title">{{ store.getters.hasDetalhesPlano ? 'Detalhes do plano' : 'Meus planos' }}</h1>
        <div v-if="!store.getters.hasDetalhesPlano" :class="`${isMobile ? 'containerCards-mobile' : 'containerCards'}`">
            <template v-if="detalhesData.subscriptions[0].dependentes.length > 0">
                <CardPlanos 
                    v-for="(dependente, index) in detalhesData.subscriptions[0].dependentes" :key="index" 
                    :dependent="dependente"
                    :isMobile="isMobile"
                    :click="() => showDetalhesPlano(index)"
                />
                <div class="boletos-minha-area" v-if="boleto && detalhesData.subscriptions[0].status === 'pending'">
                    <h1>Boletos Pendentes</h1>
                    <div class="botoes-boleto">
                        <Button type="btn-boleto" text="Ver Boleto" :click="showBoleto" />
                        <Button type="btn-boleto" text="Gerar 2º via boleto" :click="gera2via" />
                    </div>
                </div>
            </template>
            <div class="empty-store" v-else>
                <span class="material-icons-outlined iconCart">remove_shopping_cart</span>
                <p>Você não possui nenhum plano.</p>
            </div>
        </div>
        <template v-else>
            <DetalhesPlano :data="detalhesData" :isMobile="isMobile" :index="dependente_id" />
        </template>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import DetalhesPlano from './DetalhesPlanos.vue';
import CardPlanos from './CardPlanos.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Button from '../../Elements/Button.vue';
import { GET, POST } from '../../../services/api';
import "../../../assets/js/Pagseguro";
import { updateShoppingCar } from '../../../services/util';

export default {
    name: "MeusPlanos",
    props: {
        detalhesData: Object,
        isMobile: Boolean,
        boleto: Boolean
    },
    components: {
        DetalhesPlano,
        CardPlanos,
        Button
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const loader = inject("loading");
        const alert = inject("alert");
        
        const dependente_id = ref('');
        const hash = ref("");

        const showDetalhesPlano = (dependente_index) => {
            if(!props.detalhesData.cartao.numbers && !props.boleto) router.push("/finalizar-compra/pagamento");
            // else if(props.boleto && props.detalhesData.subscriptions[0].status === 'pending') return ;
            else {
                dependente_id.value = dependente_index;
                store.commit("setDetalhesPlano", true);
            }
        };

        const showBoleto = () => window.open(props.detalhesData.subscriptions[0].boleto, "_blank");

        const gerarNovoBoleto = async () => {
            try{
                const response = await POST(`segunda_via/subscription/${props.detalhesData.subscriptions[0].id_subs}`, {"senderHash": hash.value});
                window.open(response.success.boleto.transaction.paymentLink, "_blank");
                // router.go(-1);
            } catch (e) {
                alert.open("Erro", "Não foi possível gerar a segunda via.");
            }
            loader.close();
        };

        const gera2via = async () => {
            loader.open();
            const dependente = props.detalhesData.subscriptions[0].dependentes[0];
            try{
                const token = await GET(`pagseguro/get_session_id/${dependente.provider_id}`);
                if (token.length > 0) {
                    window.PagSeguroDirectPayment.setSessionId(token);
                    window.PagSeguroDirectPayment.onSenderHashReady(function (response) {
                        if (response.status == "error") {
                            return false;
                        }
                        hash.value = response.senderHash;
                        gerarNovoBoleto();
                    });
                }
            } catch (e) {
                alert.open("Erro!", "Não foi possível gerar a segunda via.");
                loader.close();
            }
        };

        onMounted( async () => {
            await updateShoppingCar()
        });

        return { store, showDetalhesPlano, dependente_id, showBoleto, gera2via}
    },
}
</script>

<style scoped>
    .container-meusplanos-mobile{
        padding: 0;
        width: 100%;
    }
    .container-meusplanos{
        padding: 10rem 0 0 6rem;
        width: calc(100% - 14.5rem);
    }

    .containerCards-mobile{
        width: 100%;
        display: flex;
        flex-flow: column;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .containerCards{
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-bottom: 4rem;
    }

    .title{
        color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 2.1875rem 0;
        text-align: left;
    }

    .empty-store{
        font-weight: bold;
        color: #c90c53;
        padding-top: 2rem;
        font-size: 1.5rem;
        user-select: none;
        text-align: center;
        margin-bottom: 2rem;
        margin: 0 auto 2rem auto;
    }

    .iconCart{
        color: #c90c53;
        font-size: 12rem;
    }

    .boletos-minha-area {
        width: 100%;
        height: 100%;
        margin-top: 1.25rem;
    }

    .boletos-minha-area h1{
       color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 2.1875rem;
    }

    .botoes-boleto{
        display: flex;
        justify-content: space-between;
        width: 17rem;
        margin: 0 auto;
    }

    @media screen and (min-width: 800px) {
        .botoes-boleto{
            margin: 0;
        }
    }

</style>