<template>
    <div v-if="!hasCookie">
        <div class="background" @click="closeCookies"></div>
        <div class="cookiesContainer">
            <p class="text-cookies">
            Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa
            <span class="link-cookies" @click="openModalTermsAndPolitics">Política de Privacidade</span> 
            e, ao continuar navegando, você concorda com estas condições.
            </p>
            <Button 
                type="cookies"
                text="Concordar"
                :click="confirmCookies"
            />
            <Button 
                type="cookies"
                text="Recusar"
                :click="closeCookies"
            />
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { setCookie } from '../../services/util';
import Button from './Button.vue';

export default {
    name: "Cookies",
    components: {
        Button
    },
    props: {
        accept: Boolean
    },
    data(props) {
        return {
            hasCookie: props.accept,
        }
    },
    methods: {
        confirmCookies(){
            setCookie('acceptCookies_comprodonto', true, 10);
            this.hasCookie = true;
        },

        closeCookies(){
            this.hasCookie = true;
        }
    },
    setup() {
        const store = useStore();

        const openModalTermsAndPolitics = () => {
            store.commit("setModalTermsAndPolitics", {display: true, content: 'politics'})
        }

        return { openModalTermsAndPolitics }
    }
}
</script>

<style scoped>
    .background{
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100vh;
        z-index: 999999;
    }

    .cookiesContainer{
        display: flex;
        flex-flow: column;
        align-items: center;
        position: fixed;
        top: 0;
        background-color: #fff;
        color: #2c3e50;
        padding: 1.2rem 2rem;
        width: 100%;
        z-index: 999999;
        width: 100%;
        font-size: .75rem;
    }
    .text-cookies{
        display: inline-block;
        width: 100%;
        text-align: justify;
        line-height: 1.5;
    }
    .link-cookies{
        text-decoration: none;
        cursor: pointer;
        color: #2c3e50;
        font-weight: 600;
        transition: color linear .3s;
    }
    .link-cookies:hover{
        color: #e91e63;
    }

    @media screen and (min-width: 800px) {
        .cookiesContainer{
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
        }
        .text-cookies{
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }
</style>
