<template>
    <form class="form-container">
        <h1 class="title">Dados Do Responsável Pelo Contrato</h1>
        <div class="inputContainer">
            <label>Nome Completo</label>
            <Input
                type="default"
                :className="store.getters.errors.cadastro[0] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Nome Completo"
                v-model="store.getters.user.name"
                :blur="updateLead"
            />
            <span class="msgErro" v-if="store.getters.errors.cadastro[0]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Telefone</label>
            <Input
                type="tel"
                :className="store.getters.errors.cadastro[1] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="(XX) XXXXX-XXXX"
                v-model="store.getters.user_cadastro.telefone"
                :blur="updateLead"
            />
            <span class="msgErro" v-if="store.getters.errors.cadastro[1]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Email</label>
            <Input
                type="default"
                :className="store.getters.errors.cadastro[2] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="Email"
                v-model="store.getters.user.email"
                :blur="updateLead"
            />
            <span class="msgErro" v-if="store.getters.errors.cadastro[2]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>CPF</label>
            <Input
                type="cpf"
                :className="store.getters.errors.cadastro[3] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="XXX.XXX.XXX-XX"
                autocomplete="off"
                v-model="store.getters.user.cpf"
            />
            <span class="msgErro" v-if="store.getters.errors.cadastro[3]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Data de Nascimento</label>
            <Input
                type="birth"
                :className="store.getters.errors.cadastro[10] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                placeholder="DD/MM/AAAA"
                v-model="store.getters.user.birth_date"
            />
            <span class="msgErro" v-if="store.getters.errors.cadastro[10]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Senha</label>
            <Input
                :type="typePassword"
                placeholder="*******"
                :className="store.getters.errors.cadastro[4] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                v-model="store.getters.user.password"
                autocomplete="new-password"
            />
            <span @click="() => changeIcon('iconPassword')" class="material-icons iconLock">{{ iconPassword }}</span>
            <span class="msgErro" v-if="store.getters.errors.cadastro[4]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
        <div class="inputContainer">
            <label>Confirme sua senha</label>
            <Input
                :type="typeConfirmPassword"
                placeholder="*******"
                :className="store.getters.errors.cadastro[5] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                v-model="store.getters.user_cadastro.confirmaPassword"
                autocomplete="new-password"
            />
            <span @click="() => changeIcon('iconConfirmPassword')" class="material-icons iconLock">{{ iconConfirmPassword }}</span>
            <span class="msgErro" v-if="store.getters.errors.cadastro[5]">
                *Verifique se o campo está preechido com dados válidos!
            </span>
        </div>
    </form>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import Input from '../../Elements/Input.vue';
import { POST, PUT } from '../../../services/api';
import { useStore } from 'vuex';
import { setCookieWithDomain, getCookie, getDeviceType, getMobileOperatingSystem } from '../../../services/util';
export default {
  components: { Input },
    name: "FormCadastro",
    props: {
        plans: Array
    },
    setup(props) {
        const loader = inject("loading");
        const alert = inject("alert");
        const store = useStore();
        
        const iconPassword = ref('lock');
        const iconConfirmPassword = ref('lock');
        const typePassword = ref('password');
        const typeConfirmPassword = ref('password');
        const id_lead = ref(null);

        const changeIcon = (icon) => {
            if (icon === 'iconPassword') {
                iconPassword.value = iconPassword.value === 'lock' ? 'lock_open' : 'lock';
                typePassword.value = typePassword.value === 'password' ? 'default' : 'password';
            } else {
                iconConfirmPassword.value = iconConfirmPassword.value === 'lock' ? 'lock_open' : 'lock';
                typeConfirmPassword.value = typeConfirmPassword.value === 'password' ? 'default' : 'password';
            }
        };

        const updateLead = async () => {
            const user = store.getters.user;
            let obj = {
                nome: `${user.name} ${user.last_name}` || "",
                email: user.email || null,
                telefone: store.getters.user_cadastro.telefone || null,
                deleted_at: null,
                device: getDeviceType() || null,
                os: getMobileOperatingSystem() || null
            }
            await PUT(`leads/${id_lead.value}`, obj);
        };

        onMounted( async () => {
            loader.open();
            id_lead.value = getCookie('lead_id_cookie');
            if(!id_lead.value){
                const user = store.getters.user;
                let obj = {
                    nome: `${user.name} ${user.last_name}`,
                    email: user.email,
                    telefone: store.getters.user_cadastro.telefone,
                    planos: props.plans,
                    device: getDeviceType(),
                    os: getMobileOperatingSystem()
                }
                try {
                    const response = await POST("leads", obj);
                    id_lead.value = response.id;
                    const domain = process.env.NODE_ENV === 'production' ? ".comprodonto.com.br" : window.location.hostname;
                    setCookieWithDomain('lead_id_cookie', id_lead, 14, domain);
                } catch (e) {
                    alert.open("Erro!", e.message);
                }
            } else {
                try{
                    await PUT(`leads/${id_lead.value}`, {planos: props.plans, deleted_at: null});
                } catch (e) {
                    alert.open("Erro!", e.message);
                }
            }
        });

        return { 
            iconPassword, 
            iconConfirmPassword, 
            changeIcon, 
            typePassword, 
            typeConfirmPassword,
            updateLead,
            store
        }
    },
}
</script>

<style scoped>
    .form-container{
        max-width: 100%;
    }

    .title{
        color: #c90c53;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-top: 3.5rem;
        margin-bottom: 2.5rem;
        position: relative;
    }

    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 1rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .iconLock{
        position: absolute;
        right: 1rem;
        top: .73rem;
        border-radius: 10px;
        color: #cb3f53;
        font-size: 1.4rem;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    .msgErro {
        color: #c90c53;
        font-size: .625rem;
        font-weight: bold;
        position: absolute;
        bottom: -15px;
        left: 0;
    }

    @media screen and (min-width: 800px){
        .form-container{
            max-width: 24rem;
        }

        .iconLock{
            right: -1.8rem;
            top: 0;
            border-radius: 10px;
            background-color: #cb3f53;
            color: white;
            font-size: 1.1rem;
            width: 22px;
            height: 2.6rem;
        }
    }
</style>