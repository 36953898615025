<template>
    <div :class="`${isMobile ? 'data-container-mobile' : 'data-container'}`">
        <div :class="`${isMobile ? 'section-mobile' : 'section'}`">
            <div :class="`${isMobile ? 'subscriber-header-mobile' : 'subscriber-header'}`">
                <span class="material-icons-outlined iconUser">person_outline</span>
                <h2 class="subtitle">Dados do titular</h2>
            </div>
            <div :class="`${isMobile ? 'info-mobile' : 'info'}`">
                <p>{{ `${data.usuario.name} ${data.usuario.last_name}` }}</p>
                <p>{{ data.usuario.email }}</p>
                <p>CPF - {{ numberToCpf(data.subscriber.cpf) }}</p>
                <p v-if="data.subscriber.rg">RG - {{ data.subscriber.rg }}</p>
                <p v-if="data.phones">{{ `+${data.phones.country_code} (${store.getters.sidebarDadosTitular.data.area_code}) ${store.getters.sidebarDadosTitular.data.number}` }}</p>
            </div>
        </div>

        <div :class="`${isMobile ? 'section-mobile' : 'section'}`">
            <div :class="`${isMobile ? 'subscriber-header-mobile' : 'subscriber-header'}`">
                <span class="material-icons-outlined iconAddress">home</span>
                <h2 class="subtitle">Endereço do titular</h2>
            </div>
            <div :class="`${isMobile ? 'info-mobile' : 'info'}`">
                <p>{{ store.getters.sidebarDadosTitular.data.street }}</p>
                <p>Número - {{ store.getters.sidebarDadosTitular.data.street_number }}</p>
                <p>{{ store.getters.sidebarDadosTitular.data.district }}</p>
                <p>{{ `${store.getters.sidebarDadosTitular.data.city} - ${store.getters.sidebarDadosTitular.data.state }`}}</p>
                <p>Nacionalidade - {{ data.subscriber.country }}</p>
                <p>CEP - {{ store.getters.sidebarDadosTitular.data.zip_code }}</p>
            </div>
        </div>
        <div :class="`${isMobile ? 'btnContainer-mobile' : 'btnContainer'}`">
            <Button text="Alterar senha" type="meus-dados" :click="() => store.commit('setSidebarSenha', true)" />
            <Button text="Alterar dados" type="meus-dados" :click="() => store.commit('setSidebarDadosTitular', {display: true, data: store.getters.sidebarDadosTitular.data})" />
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { numberToCpf } from '../../../services/formater';
import Button from '../../Elements/Button.vue';
export default {
    name: "CardTitular",
    components: {
        Button
    },
    props: {
        data: Object,
        isMobile: Boolean
    },
    created() {
        const store = useStore();
        this.dataSidebar = {
            phones: this.data.phones,
            subscriber: this.data.subscriber
        };
        store.commit("setSidebarDadosTitular", {display: false, data: this.dataSidebar});
    },
    setup() {
        const store = useStore();
        const dataSidebar = ref({});

        return { numberToCpf, store, dataSidebar }
    },
}
</script>

<style scoped>
    .data-container-mobile{
        width: 100%;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4rem;
        padding: 1.5rem;
    }
    .data-container{
        width: 23.6875rem;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4rem;
        padding: 2.5rem;
    }

    .data-container .section:first-child, .data-container-mobile .section:first-child{
        margin-bottom: 2.5rem;
    }

    .section-mobile{
        width: 80%;
        margin-bottom: 1.5rem;
    }
    .section{
        width: 100%;
    }

    .subtitle{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .subscriber-header-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .875rem;
    }
    .subscriber-header{
        display: flex;
        align-items: center;
        margin-bottom: .875rem;
    }

    .iconUser, .iconAddress{
        color: #D30C53;
        font-size: 1.8rem;
        margin-right: .8rem;
        align-self: flex-end;
    }

    .info-mobile p{
        margin-bottom: .2rem;
    }
    .info p{
        margin-bottom: .2rem;
    }

    .info-mobile{
        color: #343434;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-top: 1rem;
        margin-left: 0;
        text-align: center;
    }
    .info{
        color: #343434;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-top: 1.0625rem;
        margin-left: .3rem;
        text-align: left;
    }

    .btnContainer-mobile{
        width: 18rem;
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
    }
    .btnContainer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
    }

</style>