<template>
    <div :class="`${hasShoppingCar ? 'shopping-container hasShoppingCar animate__animated animate__fadeInDown' : 'shopping-container'}`">
        <div v-if="!isMobile" class="sidebar-top">
            <span class="material-icons-outlined icon">shopping_cart</span>
            <h1 class="title">Meu Carrinho</h1>
        </div>
        <div v-else class="sidebar-top-mobile" @click="showShoppingCar">
            <div class="loja-mobile">
                <span class="material-icons-outlined icon-mobile">shopping_cart</span>
                <span class="total-card-mobile">{{ shoppingCar.plans.length }}</span>
            </div>
            <div>
                <h1 class="title-mobile">Meu Carrinho</h1>
                <span class="total-number-mobile">{{ toMoney(shoppingCar.total/100) }}</span>
            </div>
            <span v-if="hasShoppingCar" class="material-icons-outlined iconArrow animate__animated animate__rotateIn">expand_less</span>
            <span v-else class="material-icons-outlined iconArrow animate__animated animate__rotateIn">expand_more</span>
        </div>

        <div class="total-container">
            <p class="total-text">Total</p>
            <div class="total-number-container">
                <span class="total-number">{{ toMoney(shoppingCar.total/100) }}</span>
                <span class="mes">/mês</span>
            </div>
        </div>

        <div class="itemsList">
            <template v-for="(plan, index) in shoppingCar.plans" :key="index">
                <ShoppingCarDetailsCard :plan="plan" />
            </template>
        </div>

        <div class="price-container">
            <div class="data-container">
                <p class="data-text">Taxa de adesão</p>
                <span class="data-number">{{ toMoney(shoppingCar.adesao/100) }}</span>
            </div>
            <div class="data-container">
                <p class="data-text">
                    Subtotal
                    <span
                        title="Valor da primeira mensalidade com a taxa de adesão do plano"
                        class="material-icons-outlined iconInfo"
                    >
                        info
                    </span>
                </p>
                <span class="data-number">{{ toMoney(shoppingCar.subtotal/100) }}</span>
            </div>
            <div class="data-total-container">
                <p class="total-text">Total</p>
                <div class="total-number-container">
                    <span class="total-number">{{ toMoney(shoppingCar.total/100) }}</span>
                    <span class="mes">/mês</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { toMoney } from '../../../services/formater';
import ShoppingCarDetailsCard from './ShoppingCarDetailsCard.vue';

export default {
    name: "ShoppingCarDetails",
    props: {
        shoppingCar: Object,
        isMobile: Boolean
    },
    components: {
        ShoppingCarDetailsCard
    },
    created() {
        window.addEventListener('resize', () => {
            this.hasShoppingCar = window.innerWidth > 800 ? false : this.hasShoppingCar;
        })
    },
    setup() {
        const hasShoppingCar = ref(false);

        const showShoppingCar = () => hasShoppingCar.value =!hasShoppingCar.value;

        return { toMoney, hasShoppingCar, showShoppingCar }
    },
}
</script>

<style scoped>
    .hasShoppingCar{
        height: auto !important;
        overflow: none !important;
        z-index: 40;
        box-shadow: 0 0 20px 1px rgb(160, 160, 160);
    }
    .shopping-container{
        position: absolute;
        top: 93px;
        left: -4.5%;
        background-color: #fff;
        width: 100vw;
        height: 4.6875rem;
        border: 1px solid #edebeb;
        overflow: hidden;
    }

    .sidebar-top-mobile{
        display: flex;
        align-items: center;
        position: relative;
        padding: 1.1rem 1rem;
    }
    .sidebar-top{
        display: flex;
        align-items: center;
        position: relative;
        padding: 2rem;
        color: #c90c53;
    }

    .icon-mobile{
        font-size: 28px;
    }
    .icon{
        font-size: 28px;
    }

    .title-mobile{
        color: #000;
        font-size: .875rem;
        font-weight: 500;
        margin-left: 3rem;
        margin-bottom: .25rem;
    }
    .title{
        font-size: 1.1875rem;
        font-weight: 700;
        margin-left: 1rem;
        margin-bottom: 0;
    }

    .total-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f8f7f7;
        padding: .5rem 2rem;
    }

    .total-number-container{
        display: flex;
        align-items: center;
    }

    .total-text{
        color: #000;
        font-size: .875rem;
        font-weight: 700;
        margin: 0;
    }

    .total-number-mobile{
        color: #c90c53;
        font-size: .9375rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 3rem;
    }
    .total-number{
        color: #c90c53;
        font-size: 1.375rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mes{
        color: #747474;
        font-size: .75rem;
        font-weight: 500;
        text-transform: lowercase;
        display: block;
        margin-left: .3125rem;
    }

    .itemsList{
        margin: 1rem 2rem;
        overflow-y: auto;
    }

    .data-container, .data-total-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 2rem;
    }
    .data-total-container{
        background-color: #f8f7f7;
    }

    .data-text{
        color: #000;
        font-size: .75rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .data-number{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: normal;
    }

    .iconInfo{
        font-size: 1.2rem;
        margin-left: .4rem;
        user-select: none;
    }

    .loja-mobile{
        position: relative;
    }

    .total-card-mobile{
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00fff5;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -15px;
        cursor: pointer;
        margin-top: .1rem;
    }

    .iconArrow{
        color: #FF6EA9;
        font-size: 2rem;
        cursor: pointer;
        user-select: none;
        position: absolute;
        right: 1rem;
        top: 1.2rem;
    }

    @media screen and (max-width: 500px) {
        .content-container{
            width: 100%;
        }

        .sidebar-top{
            padding: 2rem 1.5rem 0 1.5rem;
        }

        .iconClose{
            right: 1.5rem;
            top: 2rem;
        }

        .total-container{
            padding: .5rem 1.5rem;
        }

        .itemsList{
            margin: 1.5rem;
        }

        .data-container, .data-total-container{
            padding: .5rem 1.5rem;
        }
    }

    @media screen and (min-width: 800px){
        .shopping-container{
            position: relative;
            top: initial;
            left: initial;
            background-color: #fff;
            width: 22.125rem;
            height: 40rem;
            display: grid;
            grid-template-rows: 4.8875rem 3.0625rem 1fr 7.3875rem;
            border: 1px solid #edebeb;
            margin-top: 3.5rem;
        }
    }
</style>