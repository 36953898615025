<template>
    <form class="form-container">
        <h1 class="title">Endereço Do Responsável Pelo Contrato</h1>
        <div class="row">
            <div class="inputContainer col-12">
                <label>CEP</label>
                <Input
                    type="cep"
                    placeholder="CEP"
                    :className="store.getters.errors.cadastro[6] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                    v-model="store.getters.user.zip_code"
                    :blur="getCep"
                />
                <span class="msgErro" v-if="store.getters.errors.cadastro[6]">
                    *Verifique se o campo está preechido com dados válidos!
                </span>
            </div>
        </div>
        <div class="row">
            <div class="inputContainer col-12">
                <label>Cidade</label>
                <Input
                    type="default"
                    placeholder="Cidade"
                    className="input-sidebar-minhaArea"
                    readonly
                    v-model="store.getters.user.city"
                />
            </div>
        </div>
        <div class="row">
            <div class="inputContainer col-12">
                <label>Bairro</label>
                <Input
                    type="default"
                    placeholder="Bairro"
                    :className="store.getters.errors.cadastro[7] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                    v-model="store.getters.user.district"
                />
                <span class="msgErro" v-if="store.getters.errors.cadastro[7]">
                    *Verifique se o campo está preechido com dados válidos!
                </span>
            </div>
        </div>
        <div class="row">
            <div class="inputContainer col-12">
                <label>Logradouro (Rua, Av, Estrada...)</label>
                <Input
                    type="default"
                    placeholder="Logradouro"
                    :className="store.getters.errors.cadastro[8] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                    v-model="store.getters.user.street"
                />
                <span class="msgErro" v-if="store.getters.errors.cadastro[8]">
                    *Verifique se o campo está preechido com dados válidos!
                </span>
            </div>
        </div>
        <div class="row">
            <div class="inputContainer col-4">
                <label>Nº</label>
                <Input
                    type="number"
                    :className="store.getters.errors.cadastro[9] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                    placeholder="Nº"
                    :min="0"
                    v-model="store.getters.user.street_number"
                />
                <span class="msgErro" v-if="store.getters.errors.cadastro[9]">
                    *Campo obrigatório!
                </span>
            </div>
            <div class="inputContainer col-8">
                <label>Complemento (Ex: Ap 42)</label>
                <Input
                    type="default"
                    :className="store.getters.errors.cadastro[10] ? 'input-sidebar-minhaArea error' : 'input-sidebar-minhaArea'"
                    placeholder="Complemento"
                    v-model="store.getters.user.complement"
                    :blur="() => {
                    if (!validateComplement(store.getters.user.complement)) {
                        store.commit('setErrorsCadastro', { index: 10, value: true });
                    } else {
                        store.commit('setErrorsCadastro', { index: 10, value: false });
                    }
                    }"
                />
                <span class="msgErro" v-if="store.getters.errors.cadastro[10]">
                    *Verifique se o campo está preenchido com dados válidos!
                </span>
            </div>
        </div>

        <div class="terms">
            <input class="checkbox" type="checkbox" v-model="store.getters.user_cadastro.terms" />
            <p class="terms-text">
                Concordo com as
                <a @click="() => openModal('politics')" class="termsAndPolitics">Políticas de Privacidade</a>
                e
                <a @click="() => openModal('terms')" class="termsAndPolitics">Termos de Uso</a>
            </p>
        </div>
    </form>
</template>

<script>
import { useStore } from 'vuex';
import Input from '../../Elements/Input.vue';
import { GET } from '../../../services/api';
import { inject } from 'vue';

export default {
    components: { Input },
    name: "FormAddress",
    props: {
        abrangencia: Array
    },
    setup(props) {
        const loader = inject("loading");
        const alert = inject("alert");
        const store = useStore();

        const openModal = (modalType) => store.commit("setModalTermsAndPolitics", {display: true, content: modalType});
        
        const getCep = async () => {
            loader.open();
            if (store.getters.user.zip_code.length > 8) {
                store.commit("setErrorsCadastro", {index: 6, value: false});
                store.commit("setErrorsCadastro", {index: 7, value: false});
                store.commit("setErrorsCadastro", {index: 8, value: false});
                try{
                    const response = await GET(`https://viacep.com.br/ws/${store.getters.user.zip_code}/json/`);
                    let cidades = props.abrangencia.map(cidade => cidade.nome);
                    let pertence = cidades.filter(cidade => cidade == response.localidade);
                    if (pertence.length > 0) {
                        store.commit("setUserAddress", response);
                        if(store.getters.user.city === ""){
                            alert.open("Erro!", "Cidade não encontrada.");
                            store.commit("setUserAddress", "");
                        }
                    } else {
                        store.commit("setErrorsCadastro", {index: 6, value: true});
                        store.commit("setErrorsCadastro", {index: 7, value: true});
                        store.commit("setErrorsCadastro", {index: 8, value: true});
                        store.commit("setUserAddress", {zip_code: ""});
                        alert.open("Erro!", "Essa cidade não faz parte da abrangência do plano.");
                    }
                } catch(e) {
                    store.commit("setUserAddress", {zip_code: ""});
                    store.commit("setErrorsCadastro", {index: 6, value: true});
                    store.commit("setErrorsCadastro", {index: 7, value: true});
                    store.commit("setErrorsCadastro", {index: 8, value: true});
                }
            }
            loader.close();
        };
        const validateComplement = (value) => {
            const regex = /[a-zA-Z]/;
            return regex.test(value);
        };
        return { openModal, getCep, validateComplement, store }
    },
}
</script>

<style scoped>
    .form-container{
        max-width: 100%;
    }

    .title{
        color: #c90c53;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-top: 3.5rem;
        margin-bottom: 2.5rem;
        position: relative;
    }

    .inputContainer{
        position: relative;
        margin: .7rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 2rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }

    .iconLock{
        position: absolute;
        right: -1.8rem;
        top: 0;
        border-radius: 10px;
        background-color: #cb3f53;
        color: white;
        font-size: 1.1rem;
        width: 22px;
        height: 2.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    .terms{
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 3rem;
        border-radius: 4px;
        background-color: rgba(237, 235, 235, 0.6);
        padding: .9375rem;
        margin-bottom: 2.1875rem;
        user-select: none;
    }

    .terms-text{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: -0.24px;
        line-height: .85rem;
        margin-bottom: 0;
        width: 80%;
    }

    .termsAndPolitics{
        text-decoration: none;
        cursor: pointer;
    }

    .checkbox{
        width: 1rem;
        height: 1rem;
    }

    .msgErro {
        color: #c90c53;
        font-size: .625rem;
        font-weight: bold;
        position: absolute;
        bottom: -15px;
        left: 15px;
    }

    @media screen and (min-width: 800px){
        .form-container{
            max-width: 24rem;
        }
    }
</style>