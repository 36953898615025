<template>
    <Navbar type="default" :background="true" :isMobile="isMobile" />
    <Contacts :isMobile="isMobile" />
    <Footer type="default" :isMobile="isMobile" />
</template>

<script>
import Navbar from "../components/Elements/Navbar.vue";
import Contacts from "../components/Contato/Contacts.vue";
import Footer from "../components/Elements/Footer.vue";
import { ref } from "vue";

export default {
  name: "Contato",
  components: {
    Navbar,
    Contacts,
    Footer,
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;

    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const isMobile = ref(false);

    return { isMobile };
  },
};
</script>

<style scoped></style>
