<template>
    <div class="sidebar-container" v-if="store.getters.shoppingCar.display">
        <div class="background animate__animated animate__fadeIn" @click="shoppingCar.close"></div>
        <div class="content-container animate__animated animate__fadeInRight">
            <div class="sidebar-top">
                <div class="loja">
                    <span class="material-icons-outlined icon">shopping_cart</span>
                    <span class="total-card">{{ store.getters.shoppingCar.plans.length }}</span>
                </div>
                <h1 class="title">Meu Carrinho</h1>
                <span @click="shoppingCar.close" class="material-icons-outlined iconClose">close</span>
            </div>

            <div class="total-container">
                <p class="total-text">Total</p>
                <div class="total-number-container">
                    <span class="total-number">{{ toMoney(store.getters.shoppingCar.total / 100) }}</span>
                    <span class="mes">/mês</span>
                </div>
            </div>

            <div class="itemsList" v-if="store.getters.shoppingCar.plans.length > 0">
                <template v-for="(plan, index) in store.getters.shoppingCar.plans" :key="index">
                    <ShoppingCarCard :plan="plan" />
                </template>
            </div>
            <div class="itemsList" v-else></div>

            <div class="price-container">
                <div class="data-container">
                    <p class="data-text">Taxa de adesão</p>
                    <span class="data-number">{{ toMoney(store.getters.shoppingCar.adesao / 100) }}</span>
                </div>
                <div class="data-container">
                    <p class="data-text">
                        Subtotal
                        <span
                            title="Valor da primeira mensalidade com a taxa de adesão do plano"
                            class="material-icons-outlined iconInfo"
                        >
                            info
                        </span>
                    </p>
                    <span class="data-number">{{ toMoney(store.getters.shoppingCar.subtotal / 100) }}</span>
                </div>
                <div class="data-total-container">
                    <p class="total-text">Total</p>
                    <div class="total-number-container">
                        <span class="total-number">{{ toMoney(store.getters.shoppingCar.total / 100) }}</span>
                        <span class="mes">/mês</span>
                    </div>
                </div>
            </div>

            <Button type="carrinho" text="Contratar plano" :click="hirePlan" />
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { toMoney } from '../../services/formater';
import Button from '../Elements/Button.vue';
import ShoppingCarCard from './ShoppingCarCard.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { createEventosSite } from '../../services/util';

export default {
    name: "ShoppingCar",
    components: {
        Button,
        ShoppingCarCard
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const alert = inject("alert");
        const shoppingCar = inject('shoppingCar');

        const hirePlan = () => {
            createEventosSite('CLICK', 'contratar_plano');
            if (store.getters.shoppingCar.plans.length > 0) {
                router.push("/finalizar-compra/cadastro");
                shoppingCar.close();
            } else {
                alert.open("Atenção!", "Adicione itens ao seu carrinho para realizar uma compra.");
            }
        }

        return { shoppingCar, store, toMoney, hirePlan  }
    },
}
</script>

<style scoped>
    .sidebar-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 110;
        width: 100%;
        height: 100vh;
    }

    .background{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.33);
        width: 100%;
        height: 100vh;
    }

    .sidebar-top{
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: .7rem;
        padding: 2rem 2rem 0 2rem;
    }

    .content-container{
        position: fixed;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 30.3125rem;
        height: 100vh;
        display: grid;
        grid-template-rows: 4.8875rem 3.0625rem 1fr 7.3875rem 3.4375rem;
    }

    .iconClose{
        color: #C90C53;
        font-size: 2rem;
        user-select: none;
        cursor: pointer;
        position: absolute;
        right: 4rem;
        top: 2rem;
    }

    .loja{
        position: relative;
    }

    .total-card{
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00fff5;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -15px;
        cursor: pointer;
        margin-top: .1rem;
    }

    .icon{
        color: #000;
        font-size: 28px;
    }

    .title{
        color: #000;
        font-size: 1.1875rem;
        font-weight: 700;
        margin-left: 2.125rem;
        margin-bottom: 0;
    }

    .total-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f8f7f7;
        padding: .5rem 2rem;
    }

    .total-number-container{
        display: flex;
        align-items: center;
    }

    .total-text{
        color: #000;
        font-size: .875rem;
        font-weight: 700;
        margin: 0;
    }

    .total-number{
        color: #c90c53;
        font-size: 1.375rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mes{
        color: #747474;
        font-size: .75rem;
        font-weight: 500;
        text-transform: lowercase;
        display: block;
        margin-left: .3125rem;
    }

    .itemsList{
        margin: 1rem 2rem;
        overflow-y: auto;
    }

    .data-container, .data-total-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 2rem;
    }
    .data-total-container{
        background-color: #f8f7f7;
    }

    .data-text{
        color: #000;
        font-size: .75rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .data-number{
        color: #c90c53;
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: normal;
    }

    .iconInfo{
        font-size: 1.2rem;
        margin-left: .4rem;
        user-select: none;
    }

    @media screen and (max-width: 500px) {
        .content-container{
            width: 100%;
        }

        .sidebar-top{
            padding: 2rem 1.5rem 0 1.5rem;
        }

        .iconClose{
            right: 1.5rem;
            top: 2rem;
        }

        .total-container{
            padding: .5rem 1.5rem;
        }

        .itemsList{
            margin: 1.5rem;
        }

        .data-container, .data-total-container{
            padding: .5rem 1.5rem;
        }
    }
</style>