<template>
  <Navbar type="compra" :background="true" :isMobile="isMobile" :path="path" />

  <section 
    v-if="Object.keys(data).length > 0"
    :class="`${isMobile ? 'cadastrado-compra col-11 mx-auto'  : 'cadastrado-compra col-10 mx-auto'}`"
  >
    <div class="content-container">
      <div class="container-compra">
        <h1>Compra Realizada com Sucesso!</h1>

        <div class="numero-pedido">
          <h3>Pedido - {{ data.subscription.protocolo }}</h3>
        </div>

        <template v-if="data.subscription.cupons">
          <div
            v-if="data.subscription.cupons.cupons[0].vitalicio == 1"
            class="alert-card success-alert mt-5 w-75"
          >
            {{`Cupom aplicado nas mensalidades, o valor será de R$ ${parseFloat(data.subscription.custom_price/100).toFixed(2)}.`}}
          </div>
          <div
            v-else
            class="alert-card success-alert mt-5 w-75"
          >
            {{`Cupom aplicado na primeira mensalidade no valor de R$ ${parseFloat(data.subscription.cupons.valor/100).toFixed(2)}, as demais mensalidades serão no valor de R$${parseFloat(data.dependentes.reduce((total, value) => total + (value.price/100), 0)).toFixed(2)}`}}
          </div>
        </template>
                  
        <div class="titular">
          <span>Títular do plano</span>
          <h3>{{ data.subscriber.name }}</h3>           
          <h4>{{ data.subscriber.email }}</h4> 
        </div>

        <div class="mais-inf">  
          <p v-html="data.operadora.texto_email_pos_venda"></p>
          <hr />
          <p>Entre na sua Área para gerenciar seus planos, dados e pagamentos.</p>
          <p>Em caso de dúvida estamos a disposição.</p>
        </div>

        <Button type="cadastro" text="Acessar minha área" :click="() => router.push('/minha-area')" />

        <div class="documentacao-compra">
          <h3>Documentação e contrato</h3>
          <ul>
            <li v-for="arquivo in data.arquivos" :key="arquivo.id">
              <a target="_blank" :href="arquivo.path">{{ arquivo.nome }}</a>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="container-carrinho compra-finalizada active">
        <div class="title-carrinho">
          <p>Meu Plano adquirido</p>
        </div>

        <div class="container-itens-carrinho">
          <div
            class="container-item-plano"
            v-for="item in data.subscription.itens"
            :key="item.id"
          >
            <div class="container-itens">
              <h3>{{ item.nome_plano }}</h3>     
              <p>{{ data.subscriber.city }} - {{ data.subscriber.state }}</p>   
              <h4>
                R$ {{ (item.price / 100).toFixed(2).replace(".",",") }}
                <span>/ mês</span>
              </h4>
            </div>

            <div class="card-dependente-plano">
              <div class="container-img">
                <span class="material-icons-outlined iconUser">person_outline</span>
              </div>

              <div class="container-text">
                <h3>{{ item.nome_dependente }}</h3>      
                <span>{{ item.nome_plano }}</span>        
              </div>
            </div>
          </div>
        </div>

        <div class="card-uniodonto-compra-finalizada">
          <div class="container-img">
            <img src="../assets/img/pagamento/compra-06.png" alt />
          </div>

          <span>{{ data.operadora.name }}</span>

          <div class="card-endereco">
            <span>{{ data.operadora.end_logradouro }}, {{ data.operadora.end_numero }}. {{ data.operadora.end_bairro }}</span><br/>
            <span>{{ data.operadora.end_cidade }} - {{ data.operadora.end_estado }},</span>
            <span> CEP - {{ data.operadora.end_cep }}</span>
          </div>

          <span>Telefone - {{ data.operadora.telefone }}</span>
        </div>
      </div>
    </div>
  </section>
  <div v-else class="height">
    <span class="material-icons-outlined iconData">description</span>
    Dados não encontrado!
  </div>
  <Footer type="compra" :isMobile="isMobile" />
</template>
<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Footer from "../components/Elements/Footer.vue";
import Navbar from "../components/Elements/Navbar.vue";
import Button from '../components/Elements/Button.vue';
import { inject } from '@vue/runtime-core';
import { GET } from '../services/api';

export default {
  name: "Finalizado",
  components: {
    Footer,
    Navbar,
    Button
  },
  async created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });

    const loader = inject("loading");
    const alert = inject("alert");
    const route = useRoute();

    loader.open();
    const protocolo = route.params.protocolo;
    try{
      this.data = await GET(`assinatura/${protocolo}`);
    } catch (e) {
      alert.open("Erro!", "Desculpe, ocorreu um erro ao buscar algumas informações!");
    }
    loader.close();
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const path = route.path.split('/')[2];

    const isMobile = ref(false);
    const data = ref({});

    return { path, isMobile, router, data }
  }
};
</script>

<style scoped>
body {
  font-size: 14px;
  letter-spacing: .01em;
}
div {
  display: block;
}
.cadastrado-compra {
  margin-top: 9.375rem;
  padding: 0;
}
.compra {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

.content-container {
  padding-bottom: 2rem;
  display: flex;
  flex-flow: column;
  position: relative;
}

.container-compra{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.cadastrado-compra .content-container .container-compra h1:before {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAAXNSR0IB2cksfwAAAEVQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3Orq9wAAABd0Uk5TABBvv//fr19w788woCDgYNDA8ICfsFDs6uhnAAAAgElEQVR4nFXQWxLCIAwF0ESvlVgqCNL9L9UEysP70QmnmdCUSMO3O/DYntTjBC2vvYHHiFRyWCKHzpB5fgP+ryVwBJg+E2gXe1qdJiCbeOu+QEWnfYljB5UCI+qAkwIqdUj6MlqxybhQ29PyzaXutVDhtupx7Zrc+B3EIed8tvoHb/oGJlxp+U0AAAAASUVORK5CYII=);
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}
.cadastrado-compra .content-container .container-compra h1 {
    color: #000000;
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.22px;
    line-height: 34px;
    margin-bottom: 18px;
    padding-left: 27px;
    position: relative;
    
}
.cadastrado-compra .content-container .container-compra .numero-pedido h3 {
    color: #c90c53;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 600;
}
.cadastrado-compra .content-container .container-compra .titular {
    margin-top: 46px;
    margin-bottom: 36px;
    width: 343px;
    height: 82px;
    border: 1px solid #cecece;
    padding-left: 20px;
    font: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.cadastrado-compra .content-container .container-compra .mais-inf p {
    color: #343434;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 19px;
}

.cadastrado-compra .content-container .container-compra .titular span {
    color: #4b4b4b;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    background-color: #fff;
    padding-right: 10px;
    padding-left: 5px;
    position: absolute;
    top: -9px;
    left: 20px;
}
.cadastrado-compra .content-container .container-compra .titular h3 {
    color: #000000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 700;
    margin-bottom: 10px;
}
.cadastrado-compra .content-container .container-compra .titular h4 {
    color: #000000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}
p {
    line-height: 19px;
}
.cadastrado-compra .content-container .container-compra .mais-inf {
  margin-bottom: 55px;
  width: 100%;
}
.cadastrado-compra .content-container .container-compra .btn-cadastro {
    width: 342px;
    margin-top: 0;
    margin-bottom: 55px;
}
.btn-cadastro a{
    
    height: 55px;
    background-color: #c90c53;
    color: white !important;
    font-size: 19px;
    font-family: "Poppins";
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font: inherit;
    text-decoration: none !important;
    text-transform: initial;
    
}
.btn-cadastro img {
    margin-left: 48px;
}

.documentacao-compra{
  margin: 0;
  margin-bottom: 2rem;
}

.cadastrado-compra .content-container .container-compra .documentacao-compra h3 {
    color: #000000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.cadastrado-compra .content-container .container-compra .documentacao-compra ul {
    padding-left: 15px;
    width: 240px;
}
.cadastrado-compra .content-container .container-compra .documentacao-compra ul li {
    position: relative;
}
.cadastrado-compra .content-container .container-compra .documentacao-compra ul li a:first-child {
    color: #747474;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    display: block;
    text-decoration: none !important;
}
.cadastrado-compra .content-container .container-compra .btn-cadastro a {
    text-transform: initial;
}
.container-carrinho .title-carrinho {
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 38px;
    padding: 20px 0px;
}
.container-carrinho .title-carrinho p {
    color: #c90c53;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 700;
    line-height: 19px; 
    margin-bottom: 0.4rem;
}


.container-carrinho.compra-finalizada {
  height: 100% !important;
}

.md-theme-default a:not(.md-button) {
  text-decoration: none !important;
}

.btn-cadastro a {
    color: white !important;
}

.container-carrinho.compra-finalizada .title-carrinho {
    border-bottom: 1px solid #edebeb;
}
.container-carrinho .title-carrinho {
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    padding-left: 38px;
    padding: 20px 0px;
    
}
.container-carrinho.compra-finalizada .title-carrinho {
    border-bottom: 1px solid #edebeb;
}

.container-carrinho.compra-finalizada .container-itens-carrinho {
    height: 197px;
    padding-bottom: 0px;
    margin-top: 35px;
    margin-bottom: 35px;
    height: 100% !important;
    overflow-y: hidden !important;
}

.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano {
    margin-bottom: 50px;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .container-itens {
    margin-top: 0px;
    padding: 0px 35px;
}
.container-carrinho .container-itens-carrinho .container-itens h3 {
    color: #000000;
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 600;
}
.container-carrinho .container-itens-carrinho .container-itens p {
    color: #4d4d4d;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.24px;
    margin-top: 3px;
}
.container-carrinho .container-itens-carrinho .container-itens h4 {
    color: #c90c53;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.container-carrinho .container-itens-carrinho .container-itens h4 span {
    color: #747474;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-left: 5px;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano {
    display: flex;
    align-items: flex-start;
    padding: 0px 35px;
    margin-top: 37px;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano {
    display: flex;
    align-items: flex-start;
    padding: 0px 35px;
    margin-top: 37px;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano .container-img {
    margin-right: 15px;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano .container-text {
    display: flex;
    flex-direction: column;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano .container-text h3 {
    color: #000000;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
}
.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano .container-text span {
    color: #000000;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}
.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada {
    padding: 0px 35px;
    background-color: rgba(237, 235, 235, 0.4);
    height: 213px;
    margin-top: auto;
    
}
.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada .container-img {
    margin-top: 47px;
    margin-bottom: 11px;
}

.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada {
    padding: 0px 35px;
    background-color: rgba(237, 235, 235, 0.4);
    height: 213px;
    margin-top: auto;
    padding-bottom: 50px !important;
}
.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada span {
    color: #747474;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    
}
.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada span {
    color: #747474;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
}

.container-carrinho.compra-finalizada .container-itens-carrinho .container-item-plano .card-dependente-plano .container-img {
    margin-right: 15px;
}
.container-carrinho.compra-finalizada[data-v-25cd153f] {
    height: 100% !important;
}
.container-carrinho {
  width: 100%;
  height: 636px;
  font: inherit;
  margin: 0 auto;
  border: 1px solid #edebeb;
  display: flex;
  flex-direction: column;
  position: relative;
}
.container-carrinho.compra-finalizada .card-uniodonto-compra-finalizada {
    padding: 0px 35px;
    background-color: rgba(237, 235, 235, 0.4);
    height: 213px;
    margin-top: auto;
}
.card-uniodonto-compra-finalizada[data-v-25cd153f] {
    padding-bottom: 50px !important;
}
.container-itens-carrinho{
  height: 100% !important;
  overflow-y: hidden !important;
}

/*---trabalho CSS---*/


.card-uniodonto-compra-finalizada{
  padding-bottom: 50px !important;
}
.container-carrinho{
  height: 100%  !important;
}

.container-carrinho.compra-finalizada{
  height: 100%  !important;
}

@media screen and (min-width: 800px) {
  .content-container{
    flex-flow: row;
    justify-content: space-between;
  }

  .container-carrinho {
    margin: 0;
    margin-left: auto;
  }

  .container-compra{
    display: block;
  }

  .cadastrado-compra .content-container .container-compra .mais-inf {
    width: 380px;
  }

  .container-carrinho {
    width: 354px;
  }

  .documentacao-compra{
    margin: 3rem 0;
  }
}

.height{
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #C90C53;
  font-size: 2rem;
  text-align: center;
  margin: 11rem 0;
}

.iconData{
  font-size: 10rem;
  margin-bottom: 1rem;
  user-select: none;
}

.iconUser{
  color: #D30C53;
  font-size: 2rem;
  user-select: none;
}

.alert-card {
  font-size: .75rem;
  font-weight: bold;
  word-break: break-word;
  padding: .625rem 1.25rem;
  border: solid 1px rgba(0, 0, 0, 0.801);
  border-radius: .25rem;
  line-height: .875rem;
  color: #000;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0.219);
}

.success-alert {
  border: solid 1px #34a007;
  color: #34a007;
  background-color: #35a00728;
}

</style>