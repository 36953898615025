<template>
    <div :class="`${isMobile ? 'card-container-mobile' : 'card-container'}`" @click="click">
        <div class="imgContainer">
            <img :src="dependent.foto_plano ? readURL(dependent.foto_plano) : 'https://via.placeholder.com/100x100'" alt />
        </div>
        <div class="planDetails">
            <h3 class="plan">Plano<span class="planName">{{ dependent.name }}</span></h3>
            <template v-if="status">
                <span v-if="status === 'Ativo'" class="status bg-active">{{ status }}</span>
                <span v-else-if="status === 'Aguardando Pagamento'" class="status bg-pending">{{ status }}</span>
                <span v-else class="status bg-canceled">{{ status }}</span>
            </template>
            <template v-else>
                <span class="status bg-pending">Concluir Compra</span>
            </template>
        </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { createStatusPlanBadge } from '../../../services/formater';
export default {
    name: "CardPlanos",
    props: {
        dependent: Object,
        click: Function,
        isMobile: Boolean
    },
    created() {
        this.status = this.dependent.status && createStatusPlanBadge(this.dependent.status);
    },
    setup() {
        const status = ref('');

        const readURL = file => {
            if(typeof(file) == 'string'){
                return file
            }
            return window.URL.createObjectURL(file)
        };

        return { readURL, status }
    }
}
</script>

<style scoped>
    .card-container-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 28rem;
        height: 9.6875rem;
        cursor: pointer;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #fff;
        padding: 1rem 1.6875rem 1rem 1.6875rem;
        border-radius: 5px;
        margin: 0 auto 2rem auto;
    }
    .card-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 23rem;
        height: 11rem;
        cursor: pointer;
        box-shadow: 4px 4px 0 rgb(109 109 109 / 9%);
        border: 1px solid #e2e2e2;
        background-color: #fff;
        padding: 1.3125rem 1.6875rem 1rem 1.6875rem;
        border-radius: 5px;
        margin-bottom: 2rem
    }

    .imgContainer{
        display: flex;
        justify-content: flex-start;
        width: 10rem;
    }

    .imgContainer img{
       object-fit: cover;
       width: 100%;
    }

    .planDetails{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 40%;
    }

    .plan{
        color: #c90c53;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .planName{
        display: block;
        color: #000;
        font-size: 15px;
        font-weight: 700;
        margin: 5px 0;
        text-transform: initial;
    }

    .status{
        width: 100%;
        height: auto;
        min-height: .5rem;
        border-radius: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
        padding: .2rem 0;
        background-color: #17d266;
        text-align: center;
    }

    .bg-active{
        background-color: #17d266;
    }
    .bg-pending{
        background-color: #f0730d;
        font-size: .7rem;
        line-height: .8rem;
    }
    .bg-canceled{
        background-color: #d21717;
    }

    @media screen and (max-width: 400px) {
        .card-container-mobile{
            padding: 1rem;
        }
    }
</style>