<template>
    <header v-if="isMobile && type === 'default'" class="color">
        <nav class="container-header col-11 mx-auto">
            <img class="logo" @click="() => redirect('/')" src="../../assets/img/footer/footer-12.png" alt="Logotipo" />

            <div :class="`${isMobile ? 'menus-mobile' : 'menus'}`">
                <div class="loja cursor-pointer" @click="() => {
                    shoppingCar.open();
                    createEventosSite('CLICK', 'botao_carrinho');
                }">
                    <span class="material-icons-outlined icon">shopping_cart</span>
                    <span class="total-card">{{ store.getters.shoppingCar.plans.length }}</span>
                </div>

                <div class="toggle-mobile" @click="toggleSidebar">
                    <span class="material-icons-outlined icon">menu</span>
                </div>
            </div>
        </nav>
    </header>

    <header v-else-if="!isMobile && type === 'default'" :class="`${background ? 'color animate__animated animate__fadeIn': ''}`">
        <nav class="col-10 mx-auto container-header">
            <img class="logo" @click="() => redirect('/')" src="../../assets/img/header/header-01.png" alt="Logotipo" />

            <div class="social-header">
                <a
                    href="https://api.whatsapp.com/send?phone=5535933007935&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20odontol%C3%B3gicos."
                    target="_blank"
                >
                    <svg style="width:24px;height:24px;color:#fff;margin:0 .5rem;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
                    </svg>
                </a>
                <a href="https://www.facebook.com/comprodonto" target="_blank">
                    <svg style="width:24px;height:24px;color:#fff;margin:0 .5rem;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                    </svg>
                </a>
                <a href="https://www.instagram.com/comprodontooficial/" target="_blank">
                    <svg style="width:24px;height:24px;color:#fff;margin:0 .5rem;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                    </svg>
                </a>
            </div>

            <div class="menus">
                <span class="material-icons-outlined icon" @click="showMinhaArea">person</span>
                
                <div class="loja cursor-pointer" @click="() => {
                    shoppingCar.open();
                    createEventosSite('CLICK', 'botao_carrinho');
                }">
                    <span class="material-icons-outlined icon">shopping_cart</span>
                    <span class="total-card">{{ store.getters.shoppingCar.plans.length }}</span>
                </div>

                <div class="toggle-mobile" @click="toggleSidebar">
                    <span class="material-icons-outlined icon">menu</span>
                </div>
            </div>
        </nav>
    </header>

    <header v-if="isMobile && type === 'compra'" class="color-white">
        <nav class="container-header col-11 mx-auto">
            <img class="logo" @click="() => redirect('/')" src="../../../public/favicon-32x32.png" alt="Logotipo" />
             <Breadcrumb :isMobile="isMobile" :path="path" />
        </nav>
    </header>

    <header v-else-if="!isMobile && type === 'compra'" class="color-white animate__animated animate__fadeIn">
        <nav class="col-10 mx-auto container-header">
            <img class="logo" @click="() => redirect('/')" src="../../assets/img/cadastro/cadastro-01.png" alt="Logotipo" />
            <Breadcrumb :isMobile="isMobile" :path="path" />
        </nav>
    </header>




</template>

<script>
import { inject } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Breadcrumb from './Breadcrumb.vue';
import { createEventosSite } from '../../services/util';

export default{
    name: "Navbar",
    props:{
        type: String,
        background: Boolean,
        isMobile: Boolean,
        path: String || null
    },
    components: { 
        Breadcrumb 
    },
    setup() {
        const shoppingCar = inject('shoppingCar');
        const router = useRouter();
        const store = useStore();

        const redirect = (url) => router.push(url);

        const toggleSidebar = () => {
            store.commit("setSidebar", true);
            createEventosSite("CLICK", "menu_lateral");
        }

        const showMinhaArea = () => {
            if (localStorage.getItem("token_comprodonto")) {
                router.push('/minha-area')
            } else {
                store.commit("setModalLogin", {display: true});
                createEventosSite('VISUALIZACAO', 'modal_login'); 
            }
        }


        return { redirect, toggleSidebar, shoppingCar, showMinhaArea, store, createEventosSite }
    },
}
</script>

<style scoped >
    header {
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 90;
        border-bottom: 1px solid rgba(206, 206, 206, 0.5);
        user-select: none;
    }
    .color{
        background-color: #C90C53;
    }
    .color-white{
        background-color: #fff;
    }

    .container-header{
        display: flex;
        align-items: center;
        height: 93px;
        position: relative;
        padding: 0;
    }

    .social-header{
        display: flex;
        justify-content: space-between;
        margin: 0 0 0 6rem;
    }

    .loja{
        position: relative;
    }

    .logo{
        cursor: pointer;
    }

    .total-card{
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00fff5;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -15px;
        cursor: pointer;
        margin-top: .1rem;
    }

    .menus-mobile{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 35%;
        right: 0;
        width: 4.8rem;
        cursor: pointer;
        user-select: none;
    }
    .menus{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 35%;
        right: 0;
        width: 8.2rem;
        user-select: none;
    }
    .icon{
        color: white;
        font-size: 26px;
        cursor: pointer;
    }
    
</style>
