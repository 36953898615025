import store from '../../store/index';
import { createEventosSite } from './util';
import modal from './modal';

export default {
    open() {
        store.commit("showShoppingCar", true);
    },
    close() {
        store.commit("showShoppingCar", false);
    },
    start() {
        if(localStorage.getItem("planos")) {
            const plans = JSON.parse(localStorage.getItem("planos"));
            if(plans.length > 0) {
              store.commit("setShoppingCar", plans);
              this.updateTotal_Subtotal_Adesao();
            }
        }
    },
    finish() {
        store.commit("updateShoppingCarValues", {
            display: false,
            plans: [],
            total: 0, 
            subtotal: 0, 
            adesao: 0
        });
        localStorage.removeItem("planos");
    },
    updateTotal_Subtotal_Adesao() {
        let total = 0;
        let subtotal = 0;
        let adesao = 0;
        store.getters.shoppingCar.plans.map( plan => {
            total += (plan.price * plan.qtd);
            adesao += (plan.setup_fee * plan.qtd)
        })
        subtotal = total + adesao;
        store.commit("updateShoppingCarValues", {
            display: store.getters.shoppingCar.display,
            plans: store.getters.shoppingCar.plans,
            total, 
            subtotal, 
            adesao
        });
    },
    add(plan) {
        this.open();
        createEventosSite('CLICK', 'botao_add_carinho');
        let currentPlans = store.getters.shoppingCar.plans;
        let indexPlano = currentPlans.findIndex((p) => p.id === plan.id);
        indexPlano >= 0
            ? currentPlans[indexPlano].qtd += 1
            : currentPlans.push(plan)
        store.commit("setShoppingCar", currentPlans);
        localStorage.setItem("planos", JSON.stringify(currentPlans));
        this.updateTotal_Subtotal_Adesao();
    },
    remove(plan) {
        modal.open(
            "Atenção!",
            "Se você realizar essa ação um plano será removido do carrinho, deseja continuar?",
            "Sim",
            "Não",
            () => {
                let currentPlans = store.getters.shoppingCar.plans;
                let indexPlano = currentPlans.findIndex((p) => p.id === plan.id);
                currentPlans.splice(indexPlano, 1);
                store.commit("setShoppingCar", currentPlans);
                this.updateTotal_Subtotal_Adesao();
                localStorage.setItem("planos", JSON.stringify(currentPlans));
            },
            () => modal.close()
        );
    },
    sum(plan) {
        plan.qtd += 1;
        this.updateTotal_Subtotal_Adesao();
        localStorage.setItem("planos", JSON.stringify(store.getters.shoppingCar.plans));
    },
    sub(plan) {
        if (plan.qtd > 1) {
            plan.qtd -= 1;
            this.updateTotal_Subtotal_Adesao();
            localStorage.setItem("planos", JSON.stringify(store.getters.shoppingCar.plans));
        } else {
            this.remove(plan);
        }
    },
}
